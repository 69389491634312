export var defaultList = [
    //"Thumbnail [0]","Large [1]", "Title [2]", "Details [3]", "Keywords [4]",],
    
    ["../photos/featured/Photo-0142.jpg", "../photos/featured/Photo-0142_large.jpg", "Peaceful Falls", "Houston, TX | May, 2023", "Featured Nature Landscape", '2023-05'],
    ["../photos/featured/Photo-0120.jpg", "../photos/featured/Photo-0120_large.jpg", "Silhouette Tree Sunset", "Houston, TX | January, 2022", "Featured Nature Landscape", '2022-01'],
    ["../photos/featured/Photo-0090.jpg", "../photos/featured/Photo-0090_large.jpg", "Sawtooth Sunset", "Sawtooth Mountain Range, Idaho | June, 2021", "Featured Nature Landscape", '2021-06'],
    ["../photos/featured/Photo-0139.jpg", "../photos/featured/Photo-0139_large.jpg", "Cool Kid", "Houston, TX | May, 2023", "Featured Portrait FamilyKids", '2023-05'],
    ["../photos/featured/Photo-0069.jpg", "../photos/featured/Photo-0069_large.jpg", "Ara ararauna", "Blue & Yellow Macaw Parrot | Tyler Zoo | July, 2019", "Featured Nature Animals", '2019-07'],
    ["../photos/featured/Photo-0137.jpg", "../photos/featured/Photo-0137_large.jpg", "Cade", "Cypress, TX | November, 2021", "Featured Portrait FamilyKids", '2021-11'],
    ["../photos/featured/Photo-0111.jpg", "../photos/featured/Photo-0111_large.jpg", "Gradient Sunrise", "Hockley, TX | July, 2021", "Featured Nature Landscape", '2021-07'],
    ["../photos/featured/Photo-0124.jpg", "../photos/featured/Photo-0124_large.jpg", "Sandy & James", "Houston, TX | January, 2022", "Featured Portrait FamilyKids", '2022-01'],
    ["../photos/featured/Photo-0125.jpg", "../photos/featured/Photo-0125_large.jpg", "Sandy & James", "Houston, TX | January, 2022", "Black-&-White", '2022-01'],
    ["../photos/featured/Photo-0041.jpg", "../photos/featured/Photo-0041_large.jpg", "Sleeping Cheetah", "Houston Zoo - Houston, TX | December, 2018", "Featured Nature Animals", '2018-12'],
    ["../photos/featured/Photo-0114.jpg", "../photos/featured/Photo-0114_large.jpg", "Red Canoe", "Lake Apache | Stagecoach, TX | August, 2021", "Featured Nature Landscape", '2021-08'],
    ["../photos/featured/Photo-0102.jpg", "../photos/featured/Photo-0102_large.jpg", "Harley Quinn", "Comicpalooza - Houston, TX | July, 2021", "Featured Portrait Cosplay", '2021-07'],
    ["../photos/featured/Photo-0113.jpg", "../photos/featured/Photo-0113_large.jpg", "Mist on the Horizon", "Hockley, TX | July, 2021", "Featured Nature Landscape", '2021-07'],
    ["../photos/featured/Photo-0093.jpg", "../photos/featured/Photo-0093_large.jpg", "Hummingbird Feeding", "Seattle, WA | June, 2018", "Featured Nature Animals", '2018-06'],
    ["../photos/featured/Photo-0067.jpg", "../photos/featured/Photo-0067_large.jpg", "Waterwall Kiss", "Joshua & Colby - Gerald D. Hines Waterwall | Houston, TX | May, 2020", "Featured Portrait Black-&-White Wedding", '2020-05'],
    ["../photos/featured/Photo-0108.jpg", "../photos/featured/Photo-0108_large.jpg", "Field", "Hockley, TX | July, 2021", "Featured Nature Landscape", '2021-07'],
    ["../photos/featured/Photo-0130.jpg", "../photos/featured/Photo-0130_large.jpg", "James Running", "Houston, TX | January, 2022", "Featured Portrait FamilyKids", '2022-01'],
    ["../photos/featured/Photo-0086.jpg", "../photos/featured/Photo-0086_large.jpg", "Redfish Lake", "Stanley, Idaho | June, 2021", "Featured Nature Landscape", '2021-06'],
    ["../photos/featured/Photo-0141.jpg", "../photos/featured/Photo-0141_large.jpg", "You Can't Catch Me", "Houston, TX | May, 2023", "Featured Portrait FamilyKids", '2023-05'],
    ["../photos/featured/Photo-0003.jpg", "../photos/featured/Photo-0003_large.jpg", "Zebra in Fall", "Fossil Rim Wildlife Center - Glen Rose, TX | December, 2017", "Featured Nature Animals", '2017-12'],
    ["../photos/featured/Photo-0068.jpg", "../photos/featured/Photo-0068_large.jpg", "Josh & Colby", "Joshua & Colby - Gerald D. Hines Waterwall | Houston, TX | May, 2020", "Featured Portrait Wedding", '2020-05'],
    ["../photos/featured/Photo-0068-2.jpg", "../photos/featured/Photo-0068-2_large.jpg", "Josh & Colby", "Joshua & Colby - Gerald D. Hines Waterwall | Houston, TX | May, 2020", "Black-&-White", '2020-05'],
    ["../photos/featured/Photo-0109.jpg", "../photos/featured/Photo-0109_large.jpg", "Field & Tree at Sunrise", "Hockley, TX | July, 2021", "Featured Landscape Nature", '2021-07'],
    ["../photos/featured/Photo-0040.jpg", "../photos/featured/Photo-0040_large.jpg", "Jellyfish Dream", "Houston Zoo - Houston, TX | December, 2018", "Featured Animals Nature", '2018-12'],
    ["../photos/featured/Photo-0112.jpg", "../photos/featured/Photo-0112_large.jpg", "Red Alert", "Hockley, TX | July, 2021", "Featured Landscape Nature", '2021-07'],
    ["../photos/featured/Photo-0025.jpg", "../photos/featured/Photo-0025_large.jpg", "Prayer Trancends Language", "Houston's First Baptsit - Faith Center Harwin - Houston, TX | July, 2016", "Portrait Black-&-White", '2016-07'],
    ["../photos/featured/Photo-0077.jpg", "../photos/featured/Photo-0077_large.jpg", "Tree in the Sun", "Sun Valley, Idaho | June, 2021", "Featured Landscape Nature", '2021-06'],
    ["../photos/featured/Photo-0004.jpg", "../photos/featured/Photo-0004_large.jpg", "Abstract Lights", "Chihuly Garden and Glass - Seattle, WA | June, 2018", "Featured", '2018-06'],
    ["../photos/featured/Photo-0060.jpg", "../photos/featured/Photo-0060_large.jpg", "Maui", "Ra1n cosplay at Comicpalooza - Houston, TX | May, 2019", "Featured Cosplay Portrait", '2019-05'],
    ["../photos/featured/Photo-0063.jpg", "../photos/featured/Photo-0063_large.jpg", "The Lone Flower", "Turner Falls, OK | May, 2019", "Nature Flower", '2019-05'],
    ["../photos/featured/Photo-0092.jpg", "../photos/featured/Photo-0092_large.jpg", "Snoqualmie Falls", "Snoqualmie, WA | June, 2018", "Featured Nature Landscape", '2018-06'],
    ["../photos/featured/Photo-0042.jpg", "../photos/featured/Photo-0042_large.jpg", "Curious Meerkat", "Houston Zoo - Houston, TX | December, 2018", "Animals Nature", '2018-12'],
    ["../photos/featured/Photo-0121.jpg", "../photos/featured/Photo-0121_large.jpg", "James", "Houston, TX | January, 2022", "Featured Portrait FamilyKids", '2022-01'],
    ["../photos/featured/Photo-0122.jpg", "../photos/featured/Photo-0122_large.jpg", "James", "Houston, TX | January, 2022", "Black-&-White", '2022-01'],
    ["../photos/featured/Photo-0099.jpg", "../photos/featured/Photo-0099_large.jpg", "Galveston Clouds", "Galveston, TX | July, 2021", "Featured Landscape Nature", '2021-07'],
    ["../photos/featured/Photo-0012.jpg", "../photos/featured/Photo-0012_large.jpg", "Red Flower", "Beaumont Botanical Gardens - Beaumont, TX | December, 2017", "Flower Nature", '2017-12'],
    ["../photos/featured/Photo-0080.jpg", "../photos/featured/Photo-0080_large.jpg", "Sunset Bright", "Sawtooth Mountain Range, Idaho | June, 2021", "Featured Landscape Nature", '2021-06'],
    ["../photos/featured/Photo-0133.jpg", "../photos/featured/Photo-0133_large.jpg", "Adkison Boys", "Cypress, TX | November, 2021", "Featured Portrait FamilyKids", '2021-11'],
    ["../photos/featured/Photo-0119.jpg", "../photos/featured/Photo-0119_large.jpg", "Light", "San Antonio, TX | January, 2022", "Featured", '2022-01'],
    ["../photos/featured/Photo-0027.jpg", "../photos/featured/Photo-0027_large.jpg", "I Caught a Fish", "Birmingham, AL | December, 2017", "Portrait Nature", '2017-12'],
    ["../photos/featured/Photo-0001.jpg", "../photos/featured/Photo-0001_large.jpg", "Hummingbird", "Seattle, WA | June, 2018", "Featured Animals Nature", '2018-06'],
    ["../photos/featured/Photo-0088.jpg", "../photos/featured/Photo-0088_large.jpg", "Sawtooth Mountains", "Stanley, Idaho | June, 2021", "Landscape Nature", '2021-06'],
    ["../photos/featured/Photo-0089.jpg", "../photos/featured/Photo-0089_large.jpg", "Sawtooth Mountains", "Stanley, Idaho | June, 2021", "Featured Landscape Black-&-White", '2021-06'],
    ["../photos/featured/Photo-0091.jpg", "../photos/featured/Photo-0091_large.jpg", "Sun Valley", "Sun Valley, Idaho | June, 2021", "Featured Landscape Nature", '2021-06'],
    ["../photos/featured/Photo-0065.jpg", "../photos/featured/Photo-0065_large.jpg", "Josh & Colby", "Joshua & Colby - Gerald D. Hines Waterwall | Houston, TX | May, 2020", "Wedding Portrait", '2020-05'],
    ["../photos/featured/Photo-0065-2.jpg", "../photos/featured/Photo-0065-2_large.jpg", "Josh & Colby", "Joshua & Colby - Gerald D. Hines Waterwall | Houston, TX | May, 2020", "Featured Black-&-White", '2020-05'],
    ["../photos/featured/Photo-0015.jpg", "../photos/featured/Photo-0015_large.jpg", "Yellow Light", "Houston Arboretum & Nature Center - Houston, TX | September, 2018", "Featured Flower Nature", '2018-09'],
    ["../photos/featured/Photo-0085.jpg", "../photos/featured/Photo-0085_large.jpg", "Pink Sunset", "Sawtooth Mountain Range, Idaho | June, 2021", "Landscape Nature", '2021-06'],
    ["../photos/featured/Photo-0046.jpg", "../photos/featured/Photo-0046_large.jpg", "Sparkler Sendoff", "Lopez Wedding - Beaumont, TX | February, 2019", "Featured Portrait Wedding WeddingCeremony", '2019-02'],
    ["../photos/featured/Photo-0135.jpg", "../photos/featured/Photo-0135_large.jpg", "Who is she?", "Cypress, TX | November, 2021", "Featured Portrait FamilyKids", '2021-11'],
    ["../photos/featured/Photo-0136.jpg", "../photos/featured/Photo-0136_large.jpg", "Daron & Julie", "Cypress, TX | November, 2021", "Portrait FamilyKids", '2021-11'],  
    ["../photos/featured/Photo-0117.jpg", "../photos/featured/Photo-0117_large.jpg", "Pinecone", "Kled Nature Center | August, 2021", "Featured Black-&-White Nature", '2021-08'],
    ["../photos/featured/Photo-0028.jpg", "../photos/featured/Photo-0028_large.jpg", "Lauren", "Birmingham, AL | December, 2017", "Portrait", '2017-12'],
    ["../photos/featured/Photo-0047.jpg", "../photos/featured/Photo-0047_large.jpg", "Elephant Parade", "Houston Zoo - Houston, TX | December, 2018", "Featured Animals Nature", '2018-12'],
    ["../photos/featured/Photo-0066.jpg", "../photos/featured/Photo-0066_large.jpg", "Waterwall Proposal", "Joshua & Colby - Gerald D. Hines Waterwall | Houston, TX | May, 2020", "Featured Wedding Engagement Portrait", '2020-05'],
    ["../photos/featured/Photo-0044.jpg", "../photos/featured/Photo-0044_large.jpg", "Under the Veil", "Lopez Wedding - Beaumont, TX | February, 2019", "Featured Portrait Wedding WeddingCeremony", '2019-02'],
    ["../photos/featured/Photo-0118.jpg", "../photos/featured/Photo-0118_large.jpg", "Yellow Flower on lake", "Stagecoach, TX | August, 2021", "Nature Flower", '2021-08'],
    ["../photos/featured/Photo-0106.jpg", "../photos/featured/Photo-0106_large.jpg", "I am Batman", "Comicpalooza - Houston, TX | July, 2021", "Portrait Cosplay", '2021-07'],
    ["../photos/featured/Photo-0134.jpg", "../photos/featured/Photo-0134_large.jpg", "Grumpy", "Cypress, TX | November, 2021", "Portrait FamilyKids", '2021-11'],
    ["../photos/featured/Photo-0070.jpg", "../photos/featured/Photo-0070_large.jpg", "Conner", "Fairfield Baptist Church | Cypress, TX | Feburary, 2021", "Featured Portrait", '2021-02'],
    ["../photos/featured/Photo-0072.jpg", "../photos/featured/Photo-0072_large.jpg", "Conner", "Fairfield Baptist Church | Cypress, TX | Feburary, 2021", "Black-&-White", '2021-02'],
    ["../photos/featured/Photo-0116.jpg", "../photos/featured/Photo-0116_large.jpg", "Single Cloud", "Stagecoach, TX | August, 2021", "Nature", '2021-08'],
    ["../photos/featured/Photo-0023.jpg", "../photos/featured/Photo-0023_large.jpg", "Tinkerbell", "Comicpalooza - Houston, TX | May, 2018", "Featured Portrait Cosplay", '2018-05'],
    ["../photos/featured/Photo-0084.jpg", "../photos/featured/Photo-0084_large.jpg", "Road in the Mountains", "Sawtooth Mountain Range, Idaho | June, 2021", "Featured Landscape Nature", '2021-06'],
    ["../photos/featured/Photo-0127.jpg", "../photos/featured/Photo-0127_large.jpg", "James on Swingset", "Houston, TX | January, 2022", "Featured Portrait FamilyKids", '2022-01'],
    ["../photos/featured/Photo-0128.jpg", "../photos/featured/Photo-0128_large.jpg", "James on Swingset", "Houston, TX | January, 2022", "Black-&-White", '2022-01'],
    ["../photos/featured/Photo-0087.jpg", "../photos/featured/Photo-0087_large.jpg", "Horse Riding", "Stanley, Idaho | June, 2021", "Featured Nature", '2021-06'],
    ["../photos/featured/Photo-0010.jpg", "../photos/featured/Photo-0010_large.jpg", "Black & White Caves", "Longhorn Cavern State Park - Burnet, TX | December, 2017", "Nature Black-&-White", '2017-12'],
    ["../photos/featured/Photo-0071.jpg", "../photos/featured/Photo-0071_large.jpg", "Worship", "Fairfield Baptist Church | Cypress, TX | Feburary, 2021", "Featured Portrait Black-&-White", '2021-02'], 
    ["../photos/featured/Photo-0079.jpg", "../photos/featured/Photo-0079_large.jpg", "Make a Wish", "Sun Valley, Idaho | June, 2021", "Flower Nature", '2021-06'],
    ["../photos/featured/Photo-0038.jpg", "../photos/featured/Photo-0038_large.jpg", "Houston 1:8", "Houston's First Baptsit - Faith Center Harwin - Houston, TX | July, 2018", "Portrait", '2018-07'],
    ["../photos/featured/Photo-0022.jpg", "../photos/featured/Photo-0022_large.jpg", "Star Trek", "Comicpalooza - Houston, TX | May, 2018", "Featured Portrait Cosplay", '2018-05'],   
    ["../photos/featured/Photo-0100.jpg", "../photos/featured/Photo-0100_large.jpg", "Alligator Loki", "Comicpalooza - Houston, TX | July, 2021", "Portrait Cosplay", '2021-07'], 
    ["../photos/featured/Photo-0014.jpg", "../photos/featured/Photo-0014_large.jpg", "Starfish", "Fort Flagler Historical State Park - Nordland, WA | June, 2018", "Featured Animals Nature", '2018-06'],
    ["../photos/featured/Photo-0131.jpg", "../photos/featured/Photo-0131_large.jpg", "Adkison Family", "Cypress, TX | November, 2021", "Portrait FamilyKids", '2021-11'],
    ["../photos/featured/Photo-0132.jpg", "../photos/featured/Photo-0132_large.jpg", "Adkison Family", "Cypress, TX | November, 2021", "Portrait FamilyKids", '2021-11'],
    ["../photos/featured/Photo-0081.jpg", "../photos/featured/Photo-0081_large.jpg", "Sun Behind Mountains", "Sawtooth Mountain Range, Idaho | June, 2021", "Landscape Nature", '2021-06'],
    ["../photos/featured/Photo-0031.jpg", "../photos/featured/Photo-0031_large.jpg", "Harley Quin", "Comicpalooza - Houston, TX | May, 2018", "Portrait Cosplay", '2018-05'],
    ["../photos/featured/Photo-0129.jpg", "../photos/featured/Photo-0129_large.jpg", "James", "Houston, TX | January, 2022", "Featured Portrait FamilyKids", '2022-01'],
    ["../photos/featured/Photo-0115.jpg", "../photos/featured/Photo-0115_large.jpg", "Plane", "Stagecoach, TX | August, 2021", "Black-&-White", '2021-08'],     
    ["../photos/featured/Photo-0056.jpg", "../photos/featured/Photo-0056_large.jpg", "The Rings", "Alejandra & Adam - Houston, TX | October, 2019", "Featured Wedding WeddingCeremony Black-&-White", '2019-10'],
    ["../photos/featured/Photo-0054.jpg", "../photos/featured/Photo-0054_large.jpg", "The Kiss", "Alejandra & Adam - Houston, TX | October, 2019", "Wedding WeddingCeremony Portrait", '2019-10'],
    ["../photos/featured/Photo-0007.jpg", "../photos/featured/Photo-0007_large.jpg", "Emu", "Fossil Rim Wildlife Center - Glen Rose, TX | December, 2017", "Animals Nature", '2017-12'],
    ["../photos/featured/Photo-0037.jpg", "../photos/featured/Photo-0037_large.jpg", "Houston 1:8", "Houston's First Baptsit - Faith Center Harwin - Houston, TX | July, 2018", "Portrait FamilyKids", '2018-07'],
    ["../photos/featured/Photo-0020.jpg", "../photos/featured/Photo-0020_large.jpg", "Rene & Rebekah", "Houston, TX | April, 2018", "Portrait Wedding Engagement", '2018-04'],
    ["../photos/featured/Photo-0064.jpg", "../photos/featured/Photo-0064_large.jpg", "Violet Flower", "Lake Murray, OK | May, 2019", "Nature Flower", '2019-05'],
    ["../photos/featured/Photo-0098.jpg", "../photos/featured/Photo-0098_large.jpg", "Cody", "Galveston, TX | July, 2021", "Portrait", '2021-07'],
    ["../photos/featured/Photo-0061.jpg", "../photos/featured/Photo-0061_large.jpg", "Sunrise & Moon", "Houston, TX | September, 2019", "Nature Landscape", '2019-09'],
    ["../photos/featured/Photo-0045.jpg", "../photos/featured/Photo-0045_large.jpg", "The Groom", "Lopez Wedding - Beaumont, TX | February, 2019", "Portrait Wedding WeddingCeremony", '2019-02'],    
    ["../photos/featured/Photo-0101.jpg", "../photos/featured/Photo-0101_large.jpg", "Dragon Ball Z | Cooler", "Comicpalooza - Houston, TX | July, 2021", "Cosplay", '2021-07'],
    ["../photos/featured/Photo-0103.jpg", "../photos/featured/Photo-0103_large.jpg", "Link", "Comicpalooza - Houston, TX | July, 2021", "Portrait Cosplay", '2021-07'],
    ["../photos/featured/Photo-0074.jpg", "../photos/featured/Photo-0074_large.jpg", "I Smashed the Cake", "Drew's Cake Smash | Lake Murray, OK | May, 2021", "Featured Portrait FamilyKids", '2021-05'],
    ["../photos/featured/Photo-0075.jpg", "../photos/featured/Photo-0075_large.jpg", "Shoshone Falls", "Twin Falls, Idaho | June, 2021", "Landscape Nature", '2021-06'],
    ["../photos/featured/Photo-0076.jpg", "../photos/featured/Photo-0076_large.jpg", "Shoshone Falls", "Twin Falls, Idaho | June, 2021", "Landscape Black-&-White", '2021-06'],
    ["../photos/featured/Photo-0096.jpg", "../photos/featured/Photo-0096_large.jpg", "Roses", "Seattle, WA | June, 2018", "Flower Flowers Nature", '2018-06'],
    ["../photos/featured/Photo-0019.jpg", "../photos/featured/Photo-0019_large.jpg", "Lexi", "Houston, TX | March, 2018", "Portrait", '2018-04'],
    ["../photos/featured/Photo-0126.jpg", "../photos/featured/Photo-0126_large.jpg", "Sandy & James", "Houston, TX | January, 2022", "Portrait FamilyKids", '2022-01'],
    ["../photos/featured/Photo-0138.jpg", "../photos/featured/Photo-0138_large.jpg", "Daron & Julie", "Cypress, TX | November, 2021", "Portrait FamilyKids", '2021-11'],
    ["../photos/featured/Photo-0002.jpg", "../photos/featured/Photo-0002_large.jpg", "Butterfly Rock", "Enchanted Rock - Texas | December, 2017", "Animals Nature", '2017-12'],
    ["../photos/featured/Photo-0123.jpg", "../photos/featured/Photo-0123_large.jpg", "Sandy & James", "Houston, TX | January, 2022", "Portrait FamilyKids", '2022-01'],
    ["../photos/featured/Photo-0057.jpg", "../photos/featured/Photo-0057_large.jpg", "Girl Friends", "Alejandra & Adam - Houston, TX | October, 2019", "Portrait Wedding WeddingCeremony", '2019-10'],
    ["../photos/featured/Photo-0039.jpg", "../photos/featured/Photo-0039_large.jpg", "Golden", "New Orleans, LA | October, 2017", "Portrait", '2017-10'],
    ["../photos/featured/Photo-0036.jpg", "../photos/featured/Photo-0036_large.jpg", "Alice in Wonderland", "Comicpalooza - Houston, TX | May, 2018", "Portrait Cosplay", '2018-05'],
    ["../photos/featured/Photo-0053.jpg", "../photos/featured/Photo-0053_large.jpg", "The Ring", "Eddie & Shelby - Houston, TX | April, 2019", "Wedding Engagement", '2019-04'],  
    ["../photos/featured/Photo-0018.jpg", "../photos/featured/Photo-0018_large.jpg", "Jonathan & Katelyn", "Houston, TX | February, 2018", "Portrait Wedding Engagement", '2018-02'],
    ["../photos/featured/Photo-0105.jpg", "../photos/featured/Photo-0105_large.jpg", "Eivor | AC: Vahalla", "Comicpalooza - Houston, TX | July, 2021", "Cosplay", '2021-07'],
    ["../photos/featured/Photo-0104.jpg", "../photos/featured/Photo-0104_large.jpg", "Sonia Nevermind", "Comicpalooza - Houston, TX | July, 2021", "Portrait Cosplay", '2021-07'],
    ["../photos/featured/Photo-0049.jpg", "../photos/featured/Photo-0049_large.jpg", "Through the Trees", "Hunt Retreat - Fulshear, TX | January, 2019", "Landscape", '2019-01'],
    ["../photos/featured/Photo-0043.jpg", "../photos/featured/Photo-0043_large.jpg", "K & A", "Lopez Wedding - Beaumont, TX | February, 2019", "Wedding WeddingCeremony", '2019-02'],      
    ["../photos/featured/Photo-0029.jpg", "../photos/featured/Photo-0029_large.jpg", "Rebekah's Ring", "Houston, TX | April, 2018", "Wedding Engagement", '2018-04'],
    ["../photos/featured/Photo-0021.jpg", "../photos/featured/Photo-0021_large.jpg", "Running Through Water", "Summit Retreat - Pineywoods Camp - Woodlake, TX | May, 2018", "Portrait", '2018-05'],
    ["../photos/featured/Photo-0005.jpg", "../photos/featured/Photo-0005_large.jpg", "Mountain in Clouds", "Olympic National Park - Port Angeles, WA | June, 2018", "Nature Landscape", '2018-06'],
    ["../photos/featured/Photo-0026.jpg", "../photos/featured/Photo-0026_large.jpg", "The Perfect Cast", "Birmingham, AL | December, 2017", "Portrait", '2017-12'],
    ["../photos/featured/Photo-0050.jpg", "../photos/featured/Photo-0050_large.jpg", "Moon in Clouds", "Houston, TX | March, 2019", "Nature Black-&-White", '2019-03'],
    ["../photos/featured/Photo-0024.jpg", "../photos/featured/Photo-0024_large.jpg", "Sailor & Flag", "Pike Place Market - Seattle, WA | June, 2018", "Featured Portrait", '2018-06'],
    ["../photos/featured/Photo-0052.jpg", "../photos/featured/Photo-0052_large.jpg", "Swingset", "Eddie & Shelby - Houston, TX | April, 2019", "Wedding Engagement Portrait", '2019-04'],  
    ["../photos/featured/Photo-0097.jpg", "../photos/featured/Photo-0097_large.jpg", "Go for a Ride", "Seattle, WA | June, 2018", "Landscape", '2018-06'],
    ["../photos/featured/Photo-0034.jpg", "../photos/featured/Photo-0034_large.jpg", "Grayson", "Comicpalooza - Houston, TX | May, 2018", "Portrait Cosplay", '2018-05'],
    ["../photos/featured/Photo-0055.jpg", "../photos/featured/Photo-0055_large.jpg", "Family", "Alejandra & Adam - Houston, TX | October, 2019", "Wedding WeddingCeremony Portrait", '2019-10'],
    ["../photos/featured/Photo-0095.jpg", "../photos/featured/Photo-0095_large.jpg", "Hazy City", "Seattle, WA | June, 2018", "Landscape", '2018-06'],
    ["../photos/featured/Photo-0140.jpg", "../photos/featured/Photo-0140_large.jpg", "I Got Sand!", "Houston, TX | May, 2023", "Portrait FamilyKids", '2023-05'],
    ["../photos/featured/Photo-0062_CLR.jpg", "../photos/featured/Photo-0062_CLR_large.jpg", "Turner Falls", "Turner Falls, OK | May, 2019", "Nature", '2019-05'],
    ["../photos/featured/Photo-0062.jpg", "../photos/featured/Photo-0062_large.jpg", "Turner Falls", "Turner Falls, OK | May, 2019", "Black-&-White Nature", '2019-05'],
    ["../photos/featured/Photo-0017.jpg", "../photos/featured/Photo-0017_large.jpg", "Governor Mouton", "Birmingham, AL | December, 2017", "Flower Nature", '2017-12'],
    ["../photos/featured/Photo-0033.jpg", "../photos/featured/Photo-0033_large.jpg", "Power Girl", "Comicpalooza - Houston, TX | May, 2018", "Cosplay", '2018-05'],
    ["../photos/featured/Photo-0009.jpg", "../photos/featured/Photo-0009_large.jpg", "Enchanted", "Enchanted Rock - Texas | December, 2017", "Nature Landscape", '2017-12'],
    ["../photos/featured/Photo-0016.jpg", "../photos/featured/Photo-0016_large.jpg", "Muted Tones", "Birmingham, AL | December, 2017", "Flower Nature", '2017-12'],
    ["../photos/featured/Photo-0035.jpg", "../photos/featured/Photo-0035_large.jpg", "Star Wars", "Comicpalooza - Houston, TX | May, 2018", "Portrait Cosplay", '2018-05'],
    ["../photos/featured/Photo-0094.jpg", "../photos/featured/Photo-0094_large.jpg", "Mountain Landscape", "Seattle, WA | June, 2018", "Black-&-White Nature Landscape", '2018-06'],
    ["../photos/featured/Photo-0073.jpg", "../photos/featured/Photo-0073_large.jpg", "I got the One", "Drew's Cake Smash | Lake Murray, OK | May, 2021", "Portrait FamilyKids", '2021-05'],
    ["../photos/featured/Photo-0078.jpg", "../photos/featured/Photo-0078_large.jpg", "Yellow Flower", "Sun Valley, Idaho | June, 2021", "Flower Nature", '2021-06'],
    ["../photos/featured/Photo-0083.jpg", "../photos/featured/Photo-0083_large.jpg", "Van in Woods", "Sawtooth Mountain Range, Idaho | June, 2021", "Landscape Nature", '2021-06'],


    //Stagecoach, TX/Kled Nature Park 0821   

]

export var comicpalooza2021List = [
    //["Type [0]" "Thumbnail [1]","Large [2]", "More Info [3]", "Ver/Hor [4]", "Title [5]", "Details [6]", "Keywords [7]",],
    
    //Comicpalooza 2021
    ["../photos/comicpalooza2021/Comicpalooza2021_FloorShots-3.jpg", "../photos/comicpalooza2021/Comicpalooza2021_FloorShots-3_large.jpg", "Comicpalooza", "Comicpalooza - Houston, TX | July, 2021", "Featured", '2021-07'],
    ["../photos/comicpalooza2021/Comicpalooza2021_FloorShots-4.jpg", "../photos/comicpalooza2021/Comicpalooza2021_FloorShots-4_large.jpg", "Batman", "Comicpalooza - Houston, TX | July, 2021", "Featured", '2021-07'],
    ["../photos/comicpalooza2021/Comicpalooza2021_FloorShots-5.jpg", "../photos/comicpalooza2021/Comicpalooza2021_FloorShots-5_large.jpg", "Mandalorian", "Comicpalooza - Houston, TX | July, 2021", "Featured", '2021-07'],
    ["../photos/comicpalooza2021/Comicpalooza2021_FloorShots-6.jpg", "../photos/comicpalooza2021/Comicpalooza2021_FloorShots-6_large.jpg", "Comicpalooza", "Comicpalooza - Houston, TX | July, 2021", "Featured", '2021-07'],
    ["../photos/comicpalooza2021/Comicpalooza2021_FloorShots-7.jpg", "../photos/comicpalooza2021/Comicpalooza2021_FloorShots-7_large.jpg", "Eivor | AC: Vahalla", "Comicpalooza - Houston, TX | July, 2021", "Featured", '2021-07'],
    ["../photos/comicpalooza2021/Comicpalooza2021_FloorShots-8.jpg", "../photos/comicpalooza2021/Comicpalooza2021_FloorShots-8_large.jpg", "Alligator Loki", "Comicpalooza - Houston, TX | July, 2021", "Featured", '2021-07'],
    ["../photos/comicpalooza2021/Comicpalooza2021_FloorShots-9.jpg", "../photos/comicpalooza2021/Comicpalooza2021_FloorShots-9_large.jpg", "Sonia Nevermind", "Comicpalooza - Houston, TX | July, 2021", "Featured", '2021-07'],
    ["../photos/comicpalooza2021/Comicpalooza2021_FloorShots-12.jpg", "../photos/comicpalooza2021/Comicpalooza2021_FloorShots-12_large.jpg", "Spider-Man", "Comicpalooza - Houston, TX | July, 2021", "Featured", '2021-07'],
    ["../photos/comicpalooza2021/Comicpalooza2021_FloorShots-13.jpg", "../photos/comicpalooza2021/Comicpalooza2021_FloorShots-13_large.jpg", "Comicpalooza", "Comicpalooza - Houston, TX | July, 2021", "Featured", '2021-07'],
    ["../photos/comicpalooza2021/Comicpalooza2021_FloorShots-14.jpg", "../photos/comicpalooza2021/Comicpalooza2021_FloorShots-14_large.jpg", "Mando", "Comicpalooza - Houston, TX | July, 2021", "Featured", '2021-07'],
    ["../photos/comicpalooza2021/Comicpalooza2021_FloorShots-15.jpg", "../photos/comicpalooza2021/Comicpalooza2021_FloorShots-15_large.jpg", "Star Wars", "Comicpalooza - Houston, TX | July, 2021", "Featured", '2021-07'],
    ["../photos/comicpalooza2021/Comicpalooza2021_FloorShots-16.jpg", "../photos/comicpalooza2021/Comicpalooza2021_FloorShots-16_large.jpg", "Mandalorian", "Comicpalooza - Houston, TX | July, 2021", "Featured", '2021-07'],
    ["../photos/comicpalooza2021/Comicpalooza2021_FloorShots-18.jpg", "../photos/comicpalooza2021/Comicpalooza2021_FloorShots-18_large.jpg", "Mandalorian", "Comicpalooza - Houston, TX | July, 2021", "Featured", '2021-07'],
    ["../photos/comicpalooza2021/Comicpalooza2021_FloorShots-19.jpg", "../photos/comicpalooza2021/Comicpalooza2021_FloorShots-19_large.jpg", "Comicpalooza", "Comicpalooza - Houston, TX | July, 2021", "Featured", '2021-07'],
    ["../photos/comicpalooza2021/Comicpalooza2021_FloorShots-20.jpg", "../photos/comicpalooza2021/Comicpalooza2021_FloorShots-20_large.jpg", "Barbie", "Comicpalooza - Houston, TX | July, 2021", "Featured", '2021-07'],
    ["../photos/comicpalooza2021/Comicpalooza2021_FloorShots-21.jpg", "../photos/comicpalooza2021/Comicpalooza2021_FloorShots-21_large.jpg", "Link", "Comicpalooza - Houston, TX | July, 2021", "Featured", '2021-07'],
    ["../photos/comicpalooza2021/Comicpalooza2021_FloorShots-22.jpg", "../photos/comicpalooza2021/Comicpalooza2021_FloorShots-22_large.jpg", "Mario", "Comicpalooza - Houston, TX | July, 2021", "Featured", '2021-07'],
    ["../photos/comicpalooza2021/Comicpalooza2021_FloorShots-23.jpg", "../photos/comicpalooza2021/Comicpalooza2021_FloorShots-23_large.jpg", "Cruella De Vil", "Comicpalooza - Houston, TX | July, 2021", "Featured", '2021-07'],
    ["../photos/comicpalooza2021/Comicpalooza2021_FloorShots-24.jpg", "../photos/comicpalooza2021/Comicpalooza2021_FloorShots-24_large.jpg", "Star Wars", "Comicpalooza - Houston, TX | July, 2021", "Featured", '2021-07'],
    ["../photos/comicpalooza2021/Comicpalooza2021_FloorShots-26.jpg", "../photos/comicpalooza2021/Comicpalooza2021_FloorShots-26_large.jpg", "U.S. Agent", "Comicpalooza - Houston, TX | July, 2021", "Featured", '2021-07'],
    ["../photos/comicpalooza2021/Comicpalooza2021_FloorShots-27.jpg", "../photos/comicpalooza2021/Comicpalooza2021_FloorShots-27_large.jpg", "Mandalorian Wolverine", "Comicpalooza - Houston, TX | July, 2021", "Featured", '2021-07'],
    ["../photos/comicpalooza2021/Comicpalooza2021_FloorShots-28.jpg", "../photos/comicpalooza2021/Comicpalooza2021_FloorShots-28_large.jpg", "Wanda", "Comicpalooza - Houston, TX | July, 2021", "Featured", '2021-07'],
    ["../photos/comicpalooza2021/Comicpalooza2021_FloorShots-31.jpg", "../photos/comicpalooza2021/Comicpalooza2021_FloorShots-31_large.jpg", "Agatha & Wanda", "Comicpalooza - Houston, TX | July, 2021", "Featured", '2021-07'],
    ["../photos/comicpalooza2021/Comicpalooza2021_FloorShots-33.jpg", "../photos/comicpalooza2021/Comicpalooza2021_FloorShots-33_large.jpg", "Darkwing Duck", "Comicpalooza - Houston, TX | July, 2021", "Featured", '2021-07'],
    ["../photos/comicpalooza2021/Comicpalooza2021_FloorShots-34.jpg", "../photos/comicpalooza2021/Comicpalooza2021_FloorShots-34_large.jpg", "Vampire Wolverine", "Comicpalooza - Houston, TX | July, 2021", "Featured", '2021-07'],
    ["../photos/comicpalooza2021/Comicpalooza2021_FloorShots-35.jpg", "../photos/comicpalooza2021/Comicpalooza2021_FloorShots-35_large.jpg", "Comicpalooza", "Comicpalooza - Houston, TX | July, 2021", "Featured", '2021-07'],
    ["../photos/comicpalooza2021/Comicpalooza2021_FloorShots-36.jpg", "../photos/comicpalooza2021/Comicpalooza2021_FloorShots-36_large.jpg", "Animal Crossing", "Comicpalooza - Houston, TX | July, 2021", "Featured", '2021-07'],
    ["../photos/comicpalooza2021/Comicpalooza2021_FloorShots-38.jpg", "../photos/comicpalooza2021/Comicpalooza2021_FloorShots-38_large.jpg", "Venom Kingpin", "Comicpalooza - Houston, TX | July, 2021", "Featured", '2021-07'],
    ["../photos/comicpalooza2021/Comicpalooza2021_FloorShots-40.jpg", "../photos/comicpalooza2021/Comicpalooza2021_FloorShots-40_large.jpg", "Comicpalooza", "Comicpalooza - Houston, TX | July, 2021", "Featured", '2021-07'],
    ["../photos/comicpalooza2021/Comicpalooza2021_FloorShots-41.jpg", "../photos/comicpalooza2021/Comicpalooza2021_FloorShots-41_large.jpg", "White Ranger", "Comicpalooza - Houston, TX | July, 2021", "Featured", '2021-07'],
    ["../photos/comicpalooza2021/Comicpalooza2021_FloorShots-42.jpg", "../photos/comicpalooza2021/Comicpalooza2021_FloorShots-42_large.jpg", "&ldquo;Bat&rdquo; Girl", "Comicpalooza - Houston, TX | July, 2021", "Featured", '2021-07'],
    ["../photos/comicpalooza2021/Comicpalooza2021_FloorShots-43.jpg", "../photos/comicpalooza2021/Comicpalooza2021_FloorShots-43_large.jpg", "Comicpalooza", "Comicpalooza - Houston, TX | July, 2021", "Featured", '2021-07'],
    ["../photos/comicpalooza2021/Comicpalooza2021_FloorShots-44.jpg", "../photos/comicpalooza2021/Comicpalooza2021_FloorShots-44_large.jpg", "Joker", "Comicpalooza - Houston, TX | July, 2021", "Featured", '2021-07'],
    ["../photos/comicpalooza2021/Comicpalooza2021_FloorShots-45.jpg", "../photos/comicpalooza2021/Comicpalooza2021_FloorShots-45_large.jpg", "Poison Ivy", "Comicpalooza - Houston, TX | July, 2021", "Featured", '2021-07'],
    ["../photos/comicpalooza2021/Comicpalooza2021_FloorShots-46.jpg", "../photos/comicpalooza2021/Comicpalooza2021_FloorShots-46_large.jpg", "Scarecrow", "Comicpalooza - Houston, TX | July, 2021", "Featured", '2021-07'],
    ["../photos/comicpalooza2021/Comicpalooza2021_FloorShots-48.jpg", "../photos/comicpalooza2021/Comicpalooza2021_FloorShots-48_large.jpg", "Queen of Naboo", "Comicpalooza - Houston, TX | July, 2021", "Featured", '2021-07'],
    ["../photos/comicpalooza2021/Comicpalooza2021_FloorShots-49.jpg", "../photos/comicpalooza2021/Comicpalooza2021_FloorShots-49_large.jpg", "Green Arrow", "Comicpalooza - Houston, TX | July, 2021", "Featured", '2021-07'],
    ["../photos/comicpalooza2021/Comicpalooza2021_FloorShots-51.jpg", "../photos/comicpalooza2021/Comicpalooza2021_FloorShots-51_large.jpg", "Comicpalooza", "Comicpalooza - Houston, TX | July, 2021", "Featured", '2021-07'],
    ["../photos/comicpalooza2021/Comicpalooza2021_FloorShots-52.jpg", "../photos/comicpalooza2021/Comicpalooza2021_FloorShots-52_large.jpg", "Comicpalooza", "Comicpalooza - Houston, TX | July, 2021", "Featured", '2021-07'],
    ["../photos/comicpalooza2021/Comicpalooza2021_FloorShots-54.jpg", "../photos/comicpalooza2021/Comicpalooza2021_FloorShots-54_large.jpg", "Street Fighter | Chun Li", "Comicpalooza - Houston, TX | July, 2021", "Featured", '2021-07'],
    ["../photos/comicpalooza2021/Comicpalooza2021_FloorShots-55.jpg", "../photos/comicpalooza2021/Comicpalooza2021_FloorShots-55_large.jpg", "Mystique", "Comicpalooza - Houston, TX | July, 2021", "Featured", '2021-07'],
    ["../photos/comicpalooza2021/Comicpalooza2021_FloorShots-56.jpg", "../photos/comicpalooza2021/Comicpalooza2021_FloorShots-56_large.jpg", "Rogue", "Comicpalooza - Houston, TX | July, 2021", "Featured", '2021-07'],
    ["../photos/comicpalooza2021/Comicpalooza2021_FloorShots-57.jpg", "../photos/comicpalooza2021/Comicpalooza2021_FloorShots-57_large.jpg", "Dazzler", "Comicpalooza - Houston, TX | July, 2021", "Featured", '2021-07'],
    ["../photos/comicpalooza2021/Comicpalooza2021_FloorShots-58.jpg", "../photos/comicpalooza2021/Comicpalooza2021_FloorShots-58_large.jpg", "Naruto", "Comicpalooza - Houston, TX | July, 2021", "Featured", '2021-07'],
    ["../photos/comicpalooza2021/Comicpalooza2021_FloorShots-59.jpg", "../photos/comicpalooza2021/Comicpalooza2021_FloorShots-59_large.jpg", "Joker", "Comicpalooza - Houston, TX | July, 2021", "Featured", '2021-07'],
    ["../photos/comicpalooza2021/Comicpalooza2021_FloorShots-60.jpg", "../photos/comicpalooza2021/Comicpalooza2021_FloorShots-60_large.jpg", "Why is Gamora?", "Comicpalooza - Houston, TX | July, 2021", "Featured", '2021-07'],
    ["../photos/comicpalooza2021/Comicpalooza2021_FloorShots-62.jpg", "../photos/comicpalooza2021/Comicpalooza2021_FloorShots-62_large.jpg", "Star Wars", "Comicpalooza - Houston, TX | July, 2021", "Featured", '2021-07'],
    ["../photos/comicpalooza2021/Comicpalooza2021_FloorShots-65.jpg", "../photos/comicpalooza2021/Comicpalooza2021_FloorShots-65_large.jpg", "Harley Quinn", "Comicpalooza - Houston, TX | July, 2021", "Featured", '2021-07'],
    ["../photos/comicpalooza2021/Comicpalooza2021_FloorShots-66.jpg", "../photos/comicpalooza2021/Comicpalooza2021_FloorShots-66_large.jpg", "Comicpalooza", "Comicpalooza - Houston, TX | July, 2021", "Featured", '2021-07'],
    ["../photos/comicpalooza2021/Comicpalooza2021_FloorShots-68.jpg", "../photos/comicpalooza2021/Comicpalooza2021_FloorShots-68_large.jpg", "Jasmine", "Comicpalooza - Houston, TX | July, 2021", "Featured", '2021-07'],
    ["../photos/comicpalooza2021/Comicpalooza2021_FloorShots-73.jpg", "../photos/comicpalooza2021/Comicpalooza2021_FloorShots-73_large.jpg", "Comicpalooza", "Comicpalooza - Houston, TX | July, 2021", "Featured", '2021-07'],
    ["../photos/comicpalooza2021/Comicpalooza2021_FloorShots-77.jpg", "../photos/comicpalooza2021/Comicpalooza2021_FloorShots-77_large.jpg", "Black Widow", "Comicpalooza - Houston, TX | July, 2021", "Featured", '2021-07'],
    ["../photos/comicpalooza2021/Comicpalooza2021_FloorShots-79.jpg", "../photos/comicpalooza2021/Comicpalooza2021_FloorShots-79_large.jpg", "Santa Claus", "Comicpalooza - Houston, TX | July, 2021", "Featured", '2021-07'],
    ["../photos/comicpalooza2021/Comicpalooza2021_FloorShots-80.jpg", "../photos/comicpalooza2021/Comicpalooza2021_FloorShots-80_large.jpg", "Santa Claus", "Comicpalooza - Houston, TX | July, 2021", "Featured", '2021-07'],
    ["../photos/comicpalooza2021/Comicpalooza2021_FloorShots-81.jpg", "../photos/comicpalooza2021/Comicpalooza2021_FloorShots-81_large.jpg", "Tooth Fairy", "Comicpalooza - Houston, TX | July, 2021", "Featured", '2021-07'],
    ["../photos/comicpalooza2021/Comicpalooza2021_FloorShots-84.jpg", "../photos/comicpalooza2021/Comicpalooza2021_FloorShots-84_large.jpg", "President Loki", "Comicpalooza - Houston, TX | July, 2021", "Featured", '2021-07'],
    ["../photos/comicpalooza2021/Comicpalooza2021_FloorShots-88.jpg", "../photos/comicpalooza2021/Comicpalooza2021_FloorShots-88_large.jpg", "Tinker Bell", "Comicpalooza - Houston, TX | July, 2021", "Featured", '2021-07'],
    ["../photos/comicpalooza2021/Comicpalooza2021_FloorShots-92.jpg", "../photos/comicpalooza2021/Comicpalooza2021_FloorShots-92_large.jpg", "Ahsoka", "Comicpalooza - Houston, TX | July, 2021", "Featured", '2021-07'],
    ["../photos/comicpalooza2021/Comicpalooza2021_FloorShots-94.jpg", "../photos/comicpalooza2021/Comicpalooza2021_FloorShots-94_large.jpg", "Wanda", "Comicpalooza - Houston, TX | July, 2021", "Featured", '2021-07'],
    ["../photos/comicpalooza2021/Comicpalooza2021_FloorShots-98.jpg", "../photos/comicpalooza2021/Comicpalooza2021_FloorShots-98_large.jpg", "Joker", "Comicpalooza - Houston, TX | July, 2021", "Featured", '2021-07'],
    ["../photos/comicpalooza2021/Comicpalooza2021_FloorShots-99.jpg", "../photos/comicpalooza2021/Comicpalooza2021_FloorShots-99_large.jpg", "Rey", "Comicpalooza - Houston, TX | July, 2021", "Featured", '2021-07'],
    ["../photos/comicpalooza2021/Comicpalooza2021_FloorShots-103.jpg", "../photos/comicpalooza2021/Comicpalooza2021_FloorShots-103_large.jpg", "Comicpalooza", "Comicpalooza - Houston, TX | July, 2021", "Featured", '2021-07'],
    ["../photos/comicpalooza2021/Comicpalooza2021_FloorShots-105.jpg", "../photos/comicpalooza2021/Comicpalooza2021_FloorShots-105_large.jpg", "Joker", "Comicpalooza - Houston, TX | July, 2021", "Featured", '2021-07'],
    ["../photos/comicpalooza2021/Comicpalooza2021_FloorShots-106.jpg", "../photos/comicpalooza2021/Comicpalooza2021_FloorShots-106_large.jpg", "Hallow Knight", "Comicpalooza - Houston, TX | July, 2021", "Featured", '2021-07'],
    ["../photos/comicpalooza2021/Comicpalooza2021_FloorShots-108.jpg", "../photos/comicpalooza2021/Comicpalooza2021_FloorShots-108_large.jpg", "Good Omens", "Comicpalooza - Houston, TX | July, 2021", "Featured", '2021-07'],
    ["../photos/comicpalooza2021/Comicpalooza2021_FloorShots-109.jpg", "../photos/comicpalooza2021/Comicpalooza2021_FloorShots-109_large.jpg", "Comicpalooza", "Comicpalooza - Houston, TX | July, 2021", "Featured", '2021-07'],
    ["../photos/comicpalooza2021/Comicpalooza2021_FloorShots-111.jpg", "../photos/comicpalooza2021/Comicpalooza2021_FloorShots-111_large.jpg", "Freddie Mercury (Hulk)", "Comicpalooza - Houston, TX | July, 2021", "Featured", '2021-07'],
    ["../photos/comicpalooza2021/Comicpalooza2021_FloorShots-113.jpg", "../photos/comicpalooza2021/Comicpalooza2021_FloorShots-113_large.jpg", "Orc WoW", "Comicpalooza - Houston, TX | July, 2021", "Featured", '2021-07'],
    ["../photos/comicpalooza2021/Comicpalooza2021_FloorShots-115.jpg", "../photos/comicpalooza2021/Comicpalooza2021_FloorShots-115_large.jpg", "Comicpalooza", "Comicpalooza - Houston, TX | July, 2021", "Featured", '2021-07'],
    ["../photos/comicpalooza2021/Comicpalooza2021_FloorShots-117.jpg", "../photos/comicpalooza2021/Comicpalooza2021_FloorShots-117_large.jpg", "Lokis", "Comicpalooza - Houston, TX | July, 2021", "Featured", '2021-07'],
    ["../photos/comicpalooza2021/Comicpalooza2021_FloorShots-118.jpg", "../photos/comicpalooza2021/Comicpalooza2021_FloorShots-118_large.jpg", "Comicpalooza", "Comicpalooza - Houston, TX | July, 2021", "Featured", '2021-07'],
    ["../photos/comicpalooza2021/Comicpalooza2021_FloorShots-123.jpg", "../photos/comicpalooza2021/Comicpalooza2021_FloorShots-123_large.jpg", "Comicpalooza", "Comicpalooza - Houston, TX | July, 2021", "Featured", '2021-07'],
    ["../photos/comicpalooza2021/Comicpalooza2021_FloorShots-125.jpg", "../photos/comicpalooza2021/Comicpalooza2021_FloorShots-125_large.jpg", "Hellboy", "Comicpalooza - Houston, TX | July, 2021", "Featured", '2021-07'],
    ["../photos/comicpalooza2021/Comicpalooza2021_FloorShots-126.jpg", "../photos/comicpalooza2021/Comicpalooza2021_FloorShots-126_large.jpg", "Cubone | Pokemon", "Comicpalooza - Houston, TX | July, 2021", "Featured", '2021-07'],
    ["../photos/comicpalooza2021/Comicpalooza2021_FloorShots-130.jpg", "../photos/comicpalooza2021/Comicpalooza2021_FloorShots-130_large.jpg", "Comicpalooza", "Comicpalooza - Houston, TX | July, 2021", "Featured", '2021-07'],
    ["../photos/comicpalooza2021/Comicpalooza2021_FloorShots-132.jpg", "../photos/comicpalooza2021/Comicpalooza2021_FloorShots-132_large.jpg", "Walking Dead", "Comicpalooza - Houston, TX | July, 2021", "Featured", '2021-07'],
    ["../photos/comicpalooza2021/Comicpalooza2021_FloorShots-137.jpg", "../photos/comicpalooza2021/Comicpalooza2021_FloorShots-137_large.jpg", "Comicpalooza", "Comicpalooza - Houston, TX | July, 2021", "Featured", '2021-07'],
    ["../photos/comicpalooza2021/Comicpalooza2021_FloorShots-139.jpg", "../photos/comicpalooza2021/Comicpalooza2021_FloorShots-139_large.jpg", "Ghost Busters", "Comicpalooza - Houston, TX | July, 2021", "Featured", '2021-07'],
    ["../photos/comicpalooza2021/Comicpalooza2021_FloorShots-140.jpg", "../photos/comicpalooza2021/Comicpalooza2021_FloorShots-140_large.jpg", "Comicpalooza", "Comicpalooza - Houston, TX | July, 2021", "Featured", '2021-07'],
    ["../photos/comicpalooza2021/Comicpalooza2021_FloorShots-144.jpg", "../photos/comicpalooza2021/Comicpalooza2021_FloorShots-144_large.jpg", "Comicpalooza", "Comicpalooza - Houston, TX | July, 2021", "Featured", '2021-07'],
    ["../photos/comicpalooza2021/Comicpalooza2021_FloorShots-145.jpg", "../photos/comicpalooza2021/Comicpalooza2021_FloorShots-145_large.jpg", "Thor", "Comicpalooza - Houston, TX | July, 2021", "Featured", '2021-07'],
    ["../photos/comicpalooza2021/Comicpalooza2021_FloorShots-147.jpg", "../photos/comicpalooza2021/Comicpalooza2021_FloorShots-147_large.jpg", "Mandalorian", "Comicpalooza - Houston, TX | July, 2021", "Featured", '2021-07'],
    ["../photos/comicpalooza2021/Comicpalooza2021_FloorShots-148.jpg", "../photos/comicpalooza2021/Comicpalooza2021_FloorShots-148_large.jpg", "Comicpalooza", "Comicpalooza - Houston, TX | July, 2021", "Featured", '2021-07'],
    ["../photos/comicpalooza2021/Comicpalooza2021_FloorShots-150.jpg", "../photos/comicpalooza2021/Comicpalooza2021_FloorShots-150_large.jpg", "Comicpalooza", "Comicpalooza - Houston, TX | July, 2021", "Featured", '2021-07'],
    ["../photos/comicpalooza2021/Comicpalooza2021_FloorShots-156.jpg", "../photos/comicpalooza2021/Comicpalooza2021_FloorShots-156_large.jpg", "Comicpalooza", "Comicpalooza - Houston, TX | July, 2021", "Featured", '2021-07'],
    ["../photos/comicpalooza2021/Comicpalooza2021_FloorShots-157.jpg", "../photos/comicpalooza2021/Comicpalooza2021_FloorShots-157_large.jpg", "Wonder Woman", "Comicpalooza - Houston, TX | July, 2021", "Featured", '2021-07'],
    ["../photos/comicpalooza2021/Comicpalooza2021_FloorShots-159.jpg", "../photos/comicpalooza2021/Comicpalooza2021_FloorShots-159_large.jpg", "Comicpalooza", "Comicpalooza - Houston, TX | July, 2021", "Featured", '2021-07'],
    ["../photos/comicpalooza2021/Comicpalooza2021_FloorShots-161.jpg", "../photos/comicpalooza2021/Comicpalooza2021_FloorShots-161_large.jpg", "Clone Trooper", "Comicpalooza - Houston, TX | July, 2021", "Featured", '2021-07'],
    ["../photos/comicpalooza2021/Comicpalooza2021_FloorShots-163.jpg", "../photos/comicpalooza2021/Comicpalooza2021_FloorShots-163_large.jpg", "Star Wars", "Comicpalooza - Houston, TX | July, 2021", "Featured", '2021-07'],
    ["../photos/comicpalooza2021/Comicpalooza2021_FloorShots-166.jpg", "../photos/comicpalooza2021/Comicpalooza2021_FloorShots-166_large.jpg", "Harley Quinn", "Comicpalooza - Houston, TX | July, 2021", "Featured", '2021-07'],
    ["../photos/comicpalooza2021/Comicpalooza2021_FloorShots-169.jpg", "../photos/comicpalooza2021/Comicpalooza2021_FloorShots-169_large.jpg", "X-Men", "Comicpalooza - Houston, TX | July, 2021", "Featured", '2021-07'],
    ["../photos/comicpalooza2021/Comicpalooza2021_FloorShots-171.jpg", "../photos/comicpalooza2021/Comicpalooza2021_FloorShots-171_large.jpg", "Nightcrawler", "Comicpalooza - Houston, TX | July, 2021", "Featured", '2021-07'],
    ["../photos/comicpalooza2021/Comicpalooza2021_FloorShots-172.jpg", "../photos/comicpalooza2021/Comicpalooza2021_FloorShots-172_large.jpg", "Killshaw", "Comicpalooza - Houston, TX | July, 2021", "Featured", '2021-07'],
    ["../photos/comicpalooza2021/Comicpalooza2021_FloorShots-173.jpg", "../photos/comicpalooza2021/Comicpalooza2021_FloorShots-173_large.jpg", "Killshaw", "Comicpalooza - Houston, TX | July, 2021", "Featured", '2021-07'],
    ["../photos/comicpalooza2021/Comicpalooza2021_FloorShots-174.jpg", "../photos/comicpalooza2021/Comicpalooza2021_FloorShots-174_large.jpg", "Comicpalooza", "Comicpalooza - Houston, TX | July, 2021", "Featured", '2021-07'],
    ["../photos/comicpalooza2021/Comicpalooza2021_FloorShots-177.jpg", "../photos/comicpalooza2021/Comicpalooza2021_FloorShots-177_large.jpg", "Comicpalooza", "Comicpalooza - Houston, TX | July, 2021", "Featured", '2021-07'],
    ["../photos/comicpalooza2021/Comicpalooza2021_FloorShots-179.jpg", "../photos/comicpalooza2021/Comicpalooza2021_FloorShots-179_large.jpg", "Comicpalooza", "Comicpalooza - Houston, TX | July, 2021", "Featured", '2021-07'],
    ["../photos/comicpalooza2021/Comicpalooza2021_FloorShots-180.jpg", "../photos/comicpalooza2021/Comicpalooza2021_FloorShots-180_large.jpg", "Comicpalooza", "Comicpalooza - Houston, TX | July, 2021", "Featured", '2021-07'],
    ["../photos/comicpalooza2021/Comicpalooza2021_FloorShots-182.jpg", "../photos/comicpalooza2021/Comicpalooza2021_FloorShots-182_large.jpg", "Comicpalooza", "Comicpalooza - Houston, TX | July, 2021", "Featured", '2021-07'],
    ["../photos/comicpalooza2021/Comicpalooza2021_FloorShots-184.jpg", "../photos/comicpalooza2021/Comicpalooza2021_FloorShots-184_large.jpg", "Comicpalooza", "Comicpalooza - Houston, TX | July, 2021", "Featured", '2021-07'],
    ["../photos/comicpalooza2021/Comicpalooza2021_FloorShots-185.jpg", "../photos/comicpalooza2021/Comicpalooza2021_FloorShots-185_large.jpg", "Cooler | DBZ", "Comicpalooza - Houston, TX | July, 2021", "Featured", '2021-07'],

]

export var joshcolbyList = [
    //["Type [0]" "Thumbnail [1]","Large [2]", "More Info [3]", "Ver/Hor [4]", "Title [5]", "Details [6]", "Keywords [7]",],
    
    //Josh & Colby
    ["../photos/joshcolby/JoshColbyEngagement-0520-1.jpg", "../photos/joshcolby/JoshColbyEngagement-0520-1_large.jpg", "Josh & Colby", "Gerald D. Hines Waterwall | Houston, TX | May, 2020", "Featured", '2020-05'],
    ["../photos/joshcolby/JoshColbyEngagement-0520-2.jpg", "../photos/joshcolby/JoshColbyEngagement-0520-2_large.jpg", "Josh & Colby", "Gerald D. Hines Waterwall | Houston, TX | May, 2020", "Featured", '2020-05'],
    ["../photos/joshcolby/JoshColbyEngagement-0520-3.jpg", "../photos/joshcolby/JoshColbyEngagement-0520-3_large.jpg", "Josh & Colby", "Gerald D. Hines Waterwall | Houston, TX | May, 2020", "Featured", '2020-05'],
    ["../photos/joshcolby/JoshColbyEngagement-0520-4.jpg", "../photos/joshcolby/JoshColbyEngagement-0520-4_large.jpg", "Josh & Colby", "Gerald D. Hines Waterwall | Houston, TX | May, 2020", "Featured", '2020-05'],
    ["../photos/joshcolby/JoshColbyEngagement-0520-5.jpg", "../photos/joshcolby/JoshColbyEngagement-0520-5_large.jpg", "Josh & Colby", "Gerald D. Hines Waterwall | Houston, TX | May, 2020", "Featured", '2020-05'],
    ["../photos/joshcolby/JoshColbyEngagement-0520-6.jpg", "../photos/joshcolby/JoshColbyEngagement-0520-6_large.jpg", "Josh & Colby", "Gerald D. Hines Waterwall | Houston, TX | May, 2020", "Featured", '2020-05'],
    ["../photos/joshcolby/JoshColbyEngagement-0520-7.jpg", "../photos/joshcolby/JoshColbyEngagement-0520-7_large.jpg", "Josh & Colby", "Gerald D. Hines Waterwall | Houston, TX | May, 2020", "Featured", '2020-05'],
    ["../photos/joshcolby/JoshColbyEngagement-0520-8.jpg", "../photos/joshcolby/JoshColbyEngagement-0520-8_large.jpg", "Josh & Colby", "Gerald D. Hines Waterwall | Houston, TX | May, 2020", "Featured", '2020-05'],
    ["../photos/joshcolby/JoshColbyEngagement-0520-9.jpg", "../photos/joshcolby/JoshColbyEngagement-0520-9_large.jpg", "Josh & Colby", "Gerald D. Hines Waterwall | Houston, TX | May, 2020", "Featured", '2020-05'],
    ["../photos/joshcolby/JoshColbyEngagement-0520-10.jpg", "../photos/joshcolby/JoshColbyEngagement-0520-10_large.jpg", "Josh & Colby", "Gerald D. Hines Waterwall | Houston, TX | May, 2020", "Featured", '2020-05'],
    ["../photos/joshcolby/JoshColbyEngagement-0520-11.jpg", "../photos/joshcolby/JoshColbyEngagement-0520-11_large.jpg", "Josh & Colby", "Gerald D. Hines Waterwall | Houston, TX | May, 2020", "Featured", '2020-05'],
    ["../photos/joshcolby/JoshColbyEngagement-0520-12.jpg", "../photos/joshcolby/JoshColbyEngagement-0520-12_large.jpg", "Josh & Colby", "Gerald D. Hines Waterwall | Houston, TX | May, 2020", "Featured", '2020-05'],
    ["../photos/joshcolby/JoshColbyEngagement-0520-13.jpg", "../photos/joshcolby/JoshColbyEngagement-0520-13_large.jpg", "Josh & Colby", "Gerald D. Hines Waterwall | Houston, TX | May, 2020", "Featured", '2020-05'],
    ["../photos/joshcolby/JoshColbyEngagement-0520-14.jpg", "../photos/joshcolby/JoshColbyEngagement-0520-14_large.jpg", "Josh & Colby", "Gerald D. Hines Waterwall | Houston, TX | May, 2020", "Featured", '2020-05'],
    ["../photos/joshcolby/JoshColbyEngagement-0520-15.jpg", "../photos/joshcolby/JoshColbyEngagement-0520-15_large.jpg", "Josh & Colby", "Gerald D. Hines Waterwall | Houston, TX | May, 2020", "Featured", '2020-05'],
    ["../photos/joshcolby/JoshColbyEngagement-0520-16.jpg", "../photos/joshcolby/JoshColbyEngagement-0520-16_large.jpg", "Josh & Colby", "Gerald D. Hines Waterwall | Houston, TX | May, 2020", "Featured", '2020-05'],
    ["../photos/joshcolby/JoshColbyEngagement-0520-17.jpg", "../photos/joshcolby/JoshColbyEngagement-0520-17_large.jpg", "Josh & Colby", "Gerald D. Hines Waterwall | Houston, TX | May, 2020", "Featured", '2020-05'],
    ["../photos/joshcolby/JoshColbyEngagement-0520-18.jpg", "../photos/joshcolby/JoshColbyEngagement-0520-18_large.jpg", "Josh & Colby", "Gerald D. Hines Waterwall | Houston, TX | May, 2020", "Featured", '2020-05'],
    ["../photos/joshcolby/JoshColbyEngagement-0520-19.jpg", "../photos/joshcolby/JoshColbyEngagement-0520-19_large.jpg", "Josh & Colby", "Gerald D. Hines Waterwall | Houston, TX | May, 2020", "Featured", '2020-05'],
    ["../photos/joshcolby/JoshColbyEngagement-0520-20.jpg", "../photos/joshcolby/JoshColbyEngagement-0520-20_large.jpg", "Josh & Colby", "Gerald D. Hines Waterwall | Houston, TX | May, 2020", "Featured", '2020-05'],
    ["../photos/joshcolby/JoshColbyEngagement-0520-21.jpg", "../photos/joshcolby/JoshColbyEngagement-0520-21_large.jpg", "Josh & Colby", "Gerald D. Hines Waterwall | Houston, TX | May, 2020", "Featured", '2020-05'],
    ["../photos/joshcolby/JoshColbyEngagement-0520-22.jpg", "../photos/joshcolby/JoshColbyEngagement-0520-22_large.jpg", "Josh & Colby", "Gerald D. Hines Waterwall | Houston, TX | May, 2020", "Featured", '2020-05'],
    ["../photos/joshcolby/JoshColbyEngagement-0520-23.jpg", "../photos/joshcolby/JoshColbyEngagement-0520-23_large.jpg", "Josh & Colby", "Gerald D. Hines Waterwall | Houston, TX | May, 2020", "Featured", '2020-05'],
    ["../photos/joshcolby/JoshColbyEngagement-0520-24.jpg", "../photos/joshcolby/JoshColbyEngagement-0520-24_large.jpg", "Josh & Colby", "Gerald D. Hines Waterwall | Houston, TX | May, 2020", "Featured", '2020-05'],
    ["../photos/joshcolby/JoshColbyEngagement-0520-25.jpg", "../photos/joshcolby/JoshColbyEngagement-0520-25_large.jpg", "Josh & Colby", "Gerald D. Hines Waterwall | Houston, TX | May, 2020", "Featured", '2020-05'],
    ["../photos/joshcolby/JoshColbyEngagement-0520-26.jpg", "../photos/joshcolby/JoshColbyEngagement-0520-26_large.jpg", "Josh & Colby", "Gerald D. Hines Waterwall | Houston, TX | May, 2020", "Featured", '2020-05'],
    ["../photos/joshcolby/JoshColbyEngagement-0520-27.jpg", "../photos/joshcolby/JoshColbyEngagement-0520-27_large.jpg", "Josh & Colby", "Gerald D. Hines Waterwall | Houston, TX | May, 2020", "Featured", '2020-05'],
    ["../photos/joshcolby/JoshColbyEngagement-0520-28.jpg", "../photos/joshcolby/JoshColbyEngagement-0520-28_large.jpg", "Josh & Colby", "Gerald D. Hines Waterwall | Houston, TX | May, 2020", "Featured", '2020-05'],
    ["../photos/joshcolby/JoshColbyEngagement-0520-29.jpg", "../photos/joshcolby/JoshColbyEngagement-0520-29_large.jpg", "Josh & Colby", "Gerald D. Hines Waterwall | Houston, TX | May, 2020", "Featured", '2020-05'],
    ["../photos/joshcolby/JoshColbyEngagement-0520-30.jpg", "../photos/joshcolby/JoshColbyEngagement-0520-30_large.jpg", "Josh & Colby", "Gerald D. Hines Waterwall | Houston, TX | May, 2020", "Featured", '2020-05'],
    ["../photos/joshcolby/JoshColbyEngagement-0520-31.jpg", "../photos/joshcolby/JoshColbyEngagement-0520-31_large.jpg", "Josh & Colby", "Gerald D. Hines Waterwall | Houston, TX | May, 2020", "Featured", '2020-05'],
    ["../photos/joshcolby/JoshColbyEngagement-0520-32.jpg", "../photos/joshcolby/JoshColbyEngagement-0520-32_large.jpg", "Josh & Colby", "Gerald D. Hines Waterwall | Houston, TX | May, 2020", "Featured", '2020-05'],
    ["../photos/joshcolby/JoshColbyEngagement-0520-33.jpg", "../photos/joshcolby/JoshColbyEngagement-0520-33_large.jpg", "Josh & Colby", "Gerald D. Hines Waterwall | Houston, TX | May, 2020", "Featured", '2020-05'],
    ["../photos/joshcolby/JoshColbyEngagement-0520-34.jpg", "../photos/joshcolby/JoshColbyEngagement-0520-34_large.jpg", "Josh & Colby", "Gerald D. Hines Waterwall | Houston, TX | May, 2020", "Featured", '2020-05'],

]

export var idahoList = [
    //["Type [0]" "Thumbnail [1]","Large [2]", "More Info [3]", "Ver/Hor [4]", "Title [5]", "Details [6]", "Keywords [7]", 'Date'],
    
    //Idaho - 0621
    ["../photos/idaho/Idaho-0621-1.jpg", "../photos/idaho/Idaho-0621-1_large.jpg", "Shoshone Falls", "Twin Falls, Idaho | June, 2021", "Featured", '2021-06'],
    ["../photos/idaho/Idaho-0621-2.jpg", "../photos/idaho/Idaho-0621-2_large.jpg", "Brandy", "Twin Falls, Idaho | June, 2021", "Featured", '2021-06'],
    ["../photos/idaho/Idaho-0621-3.jpg", "../photos/idaho/Idaho-0621-3_large.jpg", "Shoshone Falls", "Twin Falls, Idaho | June, 2021", "Featured", '2021-06'],
    ["../photos/idaho/Idaho-0621-4.jpg", "../photos/idaho/Idaho-0621-4_large.jpg", "Shoshone Falls", "Twin Falls, Idaho | June, 2021", "Featured", '2021-06'],
    ["../photos/idaho/Idaho-0621-7.jpg", "../photos/idaho/Idaho-0621-7_large.jpg", "Shoshone Falls", "Twin Falls, Idaho | June, 2021", "Featured", '2021-06'],
    ["../photos/idaho/Idaho-0621-8.jpg", "../photos/idaho/Idaho-0621-8_large.jpg", "Shoshone Falls", "Twin Falls, Idaho | June, 2021", "Featured", '2021-06'],
    ["../photos/idaho/Idaho-0621-9.jpg", "../photos/idaho/Idaho-0621-9_large.jpg", "Shoshone Falls", "Twin Falls, Idaho | June, 2021", "Featured", '2021-06'],
    ["../photos/idaho/Idaho-0621-10.jpg", "../photos/idaho/Idaho-0621-10_large.jpg", "Shoshone Falls", "Twin Falls, Idaho | June, 2021", "Featured", '2021-06'],
    ["../photos/idaho/Idaho-0621-16.jpg", "../photos/idaho/Idaho-0621-16_large.jpg", "Bird", "Twin Falls, Idaho | June, 2021", "Featured", '2021-06'],
    ["../photos/idaho/Idaho-0621-17.jpg", "../photos/idaho/Idaho-0621-17_large.jpg", "Shoshone Falls", "Twin Falls, Idaho | June, 2021", "Featured", '2021-06'],
    ["../photos/idaho/Idaho-0621-18.jpg", "../photos/idaho/Idaho-0621-18_large.jpg", "Shoshone Falls", "Twin Falls, Idaho | June, 2021", "Featured", '2021-06'],
    ["../photos/idaho/Idaho-0621-19.jpg", "../photos/idaho/Idaho-0621-19_large.jpg", "Shoshone Falls", "Twin Falls, Idaho | June, 2021", "Featured", '2021-06'],
    ["../photos/idaho/Idaho-0621-20.jpg", "../photos/idaho/Idaho-0621-20_large.jpg", "Shoshone Falls", "Twin Falls, Idaho | June, 2021", "Featured", '2021-06'],
    ["../photos/idaho/Idaho-0621-21.jpg", "../photos/idaho/Idaho-0621-21_large.jpg", "Shoshone Falls", "Twin Falls, Idaho | June, 2021", "Featured", '2021-06'],
    ["../photos/idaho/Idaho-0621-22.jpg", "../photos/idaho/Idaho-0621-22_large.jpg", "Shoshone Falls", "Twin Falls, Idaho | June, 2021", "Featured", '2021-06'],
    ["../photos/idaho/Idaho-0621-23.jpg", "../photos/idaho/Idaho-0621-23_large.jpg", "Shoshone Falls", "Twin Falls, Idaho | June, 2021", "Featured", '2021-06'],
    ["../photos/idaho/Idaho-0621-24.jpg", "../photos/idaho/Idaho-0621-24_large.jpg", "Shoshone Falls", "Twin Falls, Idaho | June, 2021", "Featured", '2021-06'],
    ["../photos/idaho/Idaho-0621-25.jpg", "../photos/idaho/Idaho-0621-25_large.jpg", "Shoshone Falls", "Twin Falls, Idaho | June, 2021", "Featured", '2021-06'],
    ["../photos/idaho/Idaho-0621-29.jpg", "../photos/idaho/Idaho-0621-29_large.jpg", "Flower", "Sun Valley, Idaho | June, 2021", "Featured", '2021-06'],
    ["../photos/idaho/Idaho-0621-30.jpg", "../photos/idaho/Idaho-0621-30_large.jpg", "Yellow Flowers", "Sun Valley, Idaho | June, 2021", "Featured", '2021-06'],
    ["../photos/idaho/Idaho-0621-31.jpg", "../photos/idaho/Idaho-0621-31_large.jpg", "Deer", "Sun Valley, Idaho | June, 2021", "Featured", '2021-06'],
    ["../photos/idaho/Idaho-0621-33.jpg", "../photos/idaho/Idaho-0621-33_large.jpg", "Picture in a Picture", "Sun Valley, Idaho | June, 2021", "Featured", '2021-06'],
    ["../photos/idaho/Idaho-0621-34.jpg", "../photos/idaho/Idaho-0621-34_large.jpg", "White Flowers", "Sun Valley, Idaho | June, 2021", "Featured", '2021-06'],
    ["../photos/idaho/Idaho-0621-35.jpg", "../photos/idaho/Idaho-0621-35_large.jpg", "Purple Flowers", "Sun Valley, Idaho | June, 2021", "Featured", '2021-06'],
    ["../photos/idaho/Idaho-0621-36.jpg", "../photos/idaho/Idaho-0621-36_large.jpg", "Red Bird", "Sun Valley, Idaho | June, 2021", "Featured", '2021-06'],
    ["../photos/idaho/Idaho-0621-38.jpg", "../photos/idaho/Idaho-0621-38_large.jpg", "Trees", "Sun Valley, Idaho | June, 2021", "Featured", '2021-06'],
    ["../photos/idaho/Idaho-0621-39.jpg", "../photos/idaho/Idaho-0621-39_large.jpg", "Yellow Flowers", "Sun Valley, Idaho | June, 2021", "Featured", '2021-06'],
    ["../photos/idaho/Idaho-0621-40.jpg", "../photos/idaho/Idaho-0621-40_large.jpg", "Sun through the Trees", "Sun Valley, Idaho | June, 2021", "Featured", '2021-06'],
    ["../photos/idaho/Idaho-0621-43.jpg", "../photos/idaho/Idaho-0621-43_large.jpg", "Yellow Flower", "Sun Valley, Idaho | June, 2021", "Featured", '2021-06'],
    ["../photos/idaho/Idaho-0621-45.jpg", "../photos/idaho/Idaho-0621-45_large.jpg", "Yellow Flower", "Sun Valley, Idaho | June, 2021", "Featured", '2021-06'],
    ["../photos/idaho/Idaho-0621-46.jpg", "../photos/idaho/Idaho-0621-46_large.jpg", "Tree Branches", "Sun Valley, Idaho | June, 2021", "Featured", '2021-06'],
    ["../photos/idaho/Idaho-0621-48.jpg", "../photos/idaho/Idaho-0621-48_large.jpg", "Brandy", "Sun Valley, Idaho | June, 2021", "Featured", '2021-06'],
    ["../photos/idaho/Idaho-0621-49.jpg", "../photos/idaho/Idaho-0621-49_large.jpg", "Sun Valley", "Sun Valley, Idaho | June, 2021", "Featured", '2021-06'],
    ["../photos/idaho/Idaho-0621-50.jpg", "../photos/idaho/Idaho-0621-50_large.jpg", "Sun Valley", "Sun Valley, Idaho | June, 2021", "Featured", '2021-06'],
    ["../photos/idaho/Idaho-0621-54.jpg", "../photos/idaho/Idaho-0621-54_large.jpg", "Brandy", "Sun Valley, Idaho | June, 2021", "Featured", '2021-06'],
    ["../photos/idaho/Idaho-0621-56.jpg", "../photos/idaho/Idaho-0621-56_large.jpg", "Sun Valley", "Sun Valley, Idaho | June, 2021", "Featured", '2021-06'],
    ["../photos/idaho/Idaho-0621-57.jpg", "../photos/idaho/Idaho-0621-57_large.jpg", "Sun Valley", "Sun Valley, Idaho | June, 2021", "Featured", '2021-06'],
    ["../photos/idaho/Idaho-0621-58.jpg", "../photos/idaho/Idaho-0621-58_large.jpg", "Pine Cones", "Sun Valley, Idaho | June, 2021", "Featured", '2021-06'],
    ["../photos/idaho/Idaho-0621-59.jpg", "../photos/idaho/Idaho-0621-59_large.jpg", "Sun Valley", "Sun Valley, Idaho | June, 2021", "Featured", '2021-06'],
    ["../photos/idaho/Idaho-0621-60.jpg", "../photos/idaho/Idaho-0621-60_large.jpg", "Sun Valley", "Sun Valley, Idaho | June, 2021", "Featured", '2021-06'],
    ["../photos/idaho/Idaho-0621-61.jpg", "../photos/idaho/Idaho-0621-61_large.jpg", "Sun Valley", "Sun Valley, Idaho | June, 2021", "Featured", '2021-06'],
    ["../photos/idaho/Idaho-0621-62.jpg", "../photos/idaho/Idaho-0621-62_large.jpg", "White Flowers", "Sun Valley, Idaho | June, 2021", "Featured", '2021-06'],
    ["../photos/idaho/Idaho-0621-63.jpg", "../photos/idaho/Idaho-0621-63_large.jpg", "White & Yellow Flowers", "Sun Valley, Idaho | June, 2021", "Featured", '2021-06'],
    ["../photos/idaho/Idaho-0621-64.jpg", "../photos/idaho/Idaho-0621-64_large.jpg", "Make a Wish", "Sun Valley, Idaho | June, 2021", "Featured", '2021-06'],
    ["../photos/idaho/Idaho-0621-65.jpg", "../photos/idaho/Idaho-0621-65_large.jpg", "Tree", "Sun Valley, Idaho | June, 2021", "Featured", '2021-06'],
    ["../photos/idaho/Idaho-0621-66.jpg", "../photos/idaho/Idaho-0621-66_large.jpg", "White Flowers", "Sun Valley, Idaho | June, 2021", "Featured", '2021-06'],
    ["../photos/idaho/Idaho-0621-67.jpg", "../photos/idaho/Idaho-0621-67_large.jpg", "Sun Valley", "Sun Valley, Idaho | June, 2021", "Featured", '2021-06'],
    ["../photos/idaho/Idaho-0621-68.jpg", "../photos/idaho/Idaho-0621-68_large.jpg", "Sun Valley", "Sun Valley, Idaho | June, 2021", "Featured", '2021-06'],
    ["../photos/idaho/Idaho-0621-69.jpg", "../photos/idaho/Idaho-0621-69_large.jpg", "Busy Bee", "Sun Valley, Idaho | June, 2021", "Featured", '2021-06'],
    ["../photos/idaho/Idaho-0621-70.jpg", "../photos/idaho/Idaho-0621-70_large.jpg", "Yellow Flowers", "Sun Valley, Idaho | June, 2021", "Featured", '2021-06'],
    ["../photos/idaho/Idaho-0621-71.jpg", "../photos/idaho/Idaho-0621-71_large.jpg", "Sun Valley", "Sun Valley, Idaho | June, 2021", "Featured", '2021-06'],
    ["../photos/idaho/Idaho-0621-73.jpg", "../photos/idaho/Idaho-0621-73_large.jpg", "Houses in the Valley", "Sun Valley, Idaho | June, 2021", "Featured", '2021-06'],
    ["../photos/idaho/Idaho-0621-76.jpg", "../photos/idaho/Idaho-0621-76_large.jpg", "Yellow Flower", "Sun Valley, Idaho | June, 2021", "Featured", '2021-06'],
    ["../photos/idaho/Idaho-0621-77.jpg", "../photos/idaho/Idaho-0621-77_large.jpg", "Sawtooth Sunset", "Sawtooth Mountain Range, Idaho | June, 2021", "Featured", '2021-06'],
    ["../photos/idaho/Idaho-0621-78.jpg", "../photos/idaho/Idaho-0621-78_large.jpg", "Sawtooth Sunset", "Sawtooth Mountain Range, Idaho | June, 2021", "Featured", '2021-06'],
    ["../photos/idaho/Idaho-0621-79.jpg", "../photos/idaho/Idaho-0621-79_large.jpg", "Sawtooth Sunset", "Sawtooth Mountain Range, Idaho | June, 2021", "Featured", '2021-06'],
    ["../photos/idaho/Idaho-0621-81.jpg", "../photos/idaho/Idaho-0621-81_large.jpg", "Sawtooth Sunset", "Sawtooth Mountain Range, Idaho | June, 2021", "Featured", '2021-06'],
    ["../photos/idaho/Idaho-0621-83.jpg", "../photos/idaho/Idaho-0621-83_large.jpg", "Sawtooth Sunset", "Sawtooth Mountain Range, Idaho | June, 2021", "Featured", '2021-06'],
    ["../photos/idaho/Idaho-0621-84.jpg", "../photos/idaho/Idaho-0621-84_large.jpg", "Sawtooth Sunset", "Sawtooth Mountain Range, Idaho | June, 2021", "Featured", '2021-06'],
    ["../photos/idaho/Idaho-0621-85.jpg", "../photos/idaho/Idaho-0621-85_large.jpg", "Sawtooth Sunset", "Sawtooth Mountain Range, Idaho | June, 2021", "Featured", '2021-06'],
    ["../photos/idaho/Idaho-0621-86.jpg", "../photos/idaho/Idaho-0621-86_large.jpg", "Sawtooth Sunset", "Sawtooth Mountain Range, Idaho | June, 2021", "Featured", '2021-06'],
    ["../photos/idaho/Idaho-0621-88.jpg", "../photos/idaho/Idaho-0621-88_large.jpg", "Sawtooth Sunset", "Sawtooth Mountain Range, Idaho | June, 2021", "Featured", '2021-06'],
    ["../photos/idaho/Idaho-0621-89.jpg", "../photos/idaho/Idaho-0621-89_large.jpg", "Sawtooth Sunset", "Sawtooth Mountain Range, Idaho | June, 2021", "Featured", '2021-06'],
    ["../photos/idaho/Idaho-0621-90.jpg", "../photos/idaho/Idaho-0621-90_large.jpg", "Sawtooth Sunset", "Sawtooth Mountain Range, Idaho | June, 2021", "Featured", '2021-06'],
    ["../photos/idaho/Idaho-0621-91.jpg", "../photos/idaho/Idaho-0621-91_large.jpg", "Sawtooth Sunset", "Sawtooth Mountain Range, Idaho | June, 2021", "Featured", '2021-06'],
    ["../photos/idaho/Idaho-0621-92.jpg", "../photos/idaho/Idaho-0621-92_large.jpg", "Van in the Woods", "Sawtooth Mountain Range, Idaho | June, 2021", "Featured", '2021-06'],
    ["../photos/idaho/Idaho-0621-93.jpg", "../photos/idaho/Idaho-0621-93_large.jpg", "Sawtooth Sunset", "Sawtooth Mountain Range, Idaho | June, 2021", "Featured", '2021-06'],
    ["../photos/idaho/Idaho-0621-94.jpg", "../photos/idaho/Idaho-0621-94_large.jpg", "Sawtooth Sunset", "Sawtooth Mountain Range, Idaho | June, 2021", "Featured", '2021-06'],
    ["../photos/idaho/Idaho-0621-95.jpg", "../photos/idaho/Idaho-0621-95_large.jpg", "Sawtooth Sunset", "Sawtooth Mountain Range, Idaho | June, 2021", "Featured", '2021-06'],
    ["../photos/idaho/Idaho-0621-96.jpg", "../photos/idaho/Idaho-0621-96_large.jpg", "Sawtooth Sunset", "Sawtooth Mountain Range, Idaho | June, 2021", "Featured", '2021-06'],
    ["../photos/idaho/Idaho-0621-97.jpg", "../photos/idaho/Idaho-0621-97_large.jpg", "Sawtooth Sunset", "Sawtooth Mountain Range, Idaho | June, 2021", "Featured", '2021-06'],
    ["../photos/idaho/Idaho-0621-98.jpg", "../photos/idaho/Idaho-0621-98_large.jpg", "Sawtooth Sunset", "Sawtooth Mountain Range, Idaho | June, 2021", "Featured", '2021-06'],
    ["../photos/idaho/Idaho-0621-99.jpg", "../photos/idaho/Idaho-0621-99_large.jpg", "Sawtooth Sunset", "Sawtooth Mountain Range, Idaho | June, 2021", "Featured", '2021-06'],
    ["../photos/idaho/Idaho-0621-100.jpg", "../photos/idaho/Idaho-0621-100_large.jpg", "Sawtooth Sunset", "Sawtooth Mountain Range, Idaho | June, 2021", "Featured", '2021-06'],
    ["../photos/idaho/Idaho-0621-106.jpg", "../photos/idaho/Idaho-0621-106_large.jpg", "Redfish Lake", "Stanley, Idaho | June, 2021", "Featured", '2021-06'],
    ["../photos/idaho/Idaho-0621-107.jpg", "../photos/idaho/Idaho-0621-107_large.jpg", "Redfish Lake", "Stanley, Idaho | June, 2021", "Featured", '2021-06'],
    ["../photos/idaho/Idaho-0621-108.jpg", "../photos/idaho/Idaho-0621-108_large.jpg", "Redfish Lake", "Stanley, Idaho | June, 2021", "Featured", '2021-06'],
    ["../photos/idaho/Idaho-0621-109.jpg", "../photos/idaho/Idaho-0621-109_large.jpg", "Redfish Lake", "Stanley, Idaho | June, 2021", "Featured", '2021-06'],
    ["../photos/idaho/Idaho-0621-110.jpg", "../photos/idaho/Idaho-0621-110_large.jpg", "Sawtooth", "Stanley, Idaho | June, 2021", "Featured", '2021-06'],
    ["../photos/idaho/Idaho-0621-111.jpg", "../photos/idaho/Idaho-0621-111_large.jpg", "Sawtooth", "Stanley, Idaho | June, 2021", "Featured", '2021-06'],
    ["../photos/idaho/Idaho-0621-112.jpg", "../photos/idaho/Idaho-0621-112_large.jpg", "Sawtooth", "Stanley, Idaho | June, 2021", "Featured", '2021-06'],
    ["../photos/idaho/Idaho-0621-113.jpg", "../photos/idaho/Idaho-0621-113_large.jpg", "Sawtooth", "Stanley, Idaho | June, 2021", "Featured", '2021-06'],
    ["../photos/idaho/Idaho-0621-114.jpg", "../photos/idaho/Idaho-0621-114_large.jpg", "Sawtooth", "Stanley, Idaho | June, 2021", "Featured", '2021-06'],
    ["../photos/idaho/Idaho-0621-115.jpg", "../photos/idaho/Idaho-0621-115_large.jpg", "Sawtooth", "Stanley, Idaho | June, 2021", "Featured", '2021-06'],
    ["../photos/idaho/Idaho-0621-117.jpg", "../photos/idaho/Idaho-0621-117_large.jpg", "Daytime Moon", "Stanley, Idaho | June, 2021", "Featured", '2021-06'],
    ["../photos/idaho/Idaho-0621-118.jpg", "../photos/idaho/Idaho-0621-118_large.jpg", "Horseback Riding", "Stanley, Idaho | June, 2021", "Featured", '2021-06'],
    ["../photos/idaho/Idaho-0621-119.jpg", "../photos/idaho/Idaho-0621-119_large.jpg", "Cat", "Stanley, Idaho | June, 2021", "Featured", '2021-06'],
    ["../photos/idaho/Idaho-0621-120.jpg", "../photos/idaho/Idaho-0621-120_large.jpg", "Horseback Riding", "Stanley, Idaho | June, 2021", "Featured", '2021-06'],
    ["../photos/idaho/Idaho-0621-122.jpg", "../photos/idaho/Idaho-0621-122_large.jpg", "Sawtooth", "Stanley, Idaho | June, 2021", "Featured", '2021-06'],
    ["../photos/idaho/Idaho-0621-124.jpg", "../photos/idaho/Idaho-0621-124_large.jpg", "Horseback Riding", "Stanley, Idaho | June, 2021", "Featured", '2021-06'],
    ["../photos/idaho/Idaho-0621-132.jpg", "../photos/idaho/Idaho-0621-132_large.jpg", "Horseback Riding", "Stanley, Idaho | June, 2021", "Featured", '2021-06'],
    ["../photos/idaho/Idaho-0621-135.jpg", "../photos/idaho/Idaho-0621-135_large.jpg", "Sawtooth", "Stanley, Idaho | June, 2021", "Featured", '2021-06'],
    ["../photos/idaho/Idaho-0621-137.jpg", "../photos/idaho/Idaho-0621-137_large.jpg", "Sawtooth", "Stanley, Idaho | June, 2021", "Featured", '2021-06'],
    ["../photos/idaho/Idaho-0621-138.jpg", "../photos/idaho/Idaho-0621-138_large.jpg", "Sawtooth", "Stanley, Idaho | June, 2021", "Featured", '2021-06'],
    ["../photos/idaho/Idaho-0621-139.jpg", "../photos/idaho/Idaho-0621-139_large.jpg", "Sawtooth", "Stanley, Idaho | June, 2021", "Featured", '2021-06'],
    ["../photos/idaho/Idaho-0621-140.jpg", "../photos/idaho/Idaho-0621-140_large.jpg", "Sawtooth", "Stanley, Idaho | June, 2021", "Featured", '2021-06'],
    ["../photos/idaho/Idaho-0621-141.jpg", "../photos/idaho/Idaho-0621-141_large.jpg", "Horseback Riding", "Stanley, Idaho | June, 2021", "Featured", '2021-06'],
    ["../photos/idaho/Idaho-0621-142.jpg", "../photos/idaho/Idaho-0621-142_large.jpg", "Sawtooth", "Stanley, Idaho | June, 2021", "Featured", '2021-06'],
    ["../photos/idaho/Idaho-0621-143.jpg", "../photos/idaho/Idaho-0621-143_large.jpg", "Sawtooth", "Stanley, Idaho | June, 2021", "Featured", '2021-06'],
    ["../photos/idaho/Idaho-0621-145.jpg", "../photos/idaho/Idaho-0621-145_large.jpg", "Sawtooth", "Stanley, Idaho | June, 2021", "Featured", '2021-06'],
    ["../photos/idaho/Idaho-0621-149.jpg", "../photos/idaho/Idaho-0621-149_large.jpg", "Sawtooth", "Stanley, Idaho | June, 2021", "Featured", '2021-06'],
    ["../photos/idaho/Idaho-0621-150.jpg", "../photos/idaho/Idaho-0621-150_large.jpg", "Sawtooth", "Stanley, Idaho | June, 2021", "Featured", '2021-06'],
    ["../photos/idaho/Idaho-0621-151.jpg", "../photos/idaho/Idaho-0621-151_large.jpg", "Sawtooth", "Stanley, Idaho | June, 2021", "Featured", '2021-06'],
    ["../photos/idaho/Idaho-0621-152.jpg", "../photos/idaho/Idaho-0621-152_large.jpg", "Sawtooth", "Stanley, Idaho | June, 2021", "Featured", '2021-06'],
    ["../photos/idaho/Idaho-0621-153.jpg", "../photos/idaho/Idaho-0621-153_large.jpg", "Sawtooth", "Stanley, Idaho | June, 2021", "Featured", '2021-06'],
    ["../photos/idaho/Idaho-0621-154.jpg", "../photos/idaho/Idaho-0621-154_large.jpg", "Sawtooth", "Stanley, Idaho | June, 2021", "Featured", '2021-06'],
    ["../photos/idaho/Idaho-0621-155.jpg", "../photos/idaho/Idaho-0621-155_large.jpg", "Horseback Riding", "Stanley, Idaho | June, 2021", "Featured", '2021-06'],
    ["../photos/idaho/Idaho-0621-156.jpg", "../photos/idaho/Idaho-0621-156_large.jpg", "Horseback Riding", "Stanley, Idaho | June, 2021", "Featured", '2021-06'],
    ["../photos/idaho/Idaho-0621-157.jpg", "../photos/idaho/Idaho-0621-157_large.jpg", "Sawtooth", "Stanley, Idaho | June, 2021", "Featured", '2021-06'],
    ["../photos/idaho/Idaho-0621-159.jpg", "../photos/idaho/Idaho-0621-159_large.jpg", "Sawtooth", "Stanley, Idaho | June, 2021", "Featured", '2021-06'],
    ["../photos/idaho/Idaho-0621-161.jpg", "../photos/idaho/Idaho-0621-161_large.jpg", "Sawtooth", "Stanley, Idaho | June, 2021", "Featured", '2021-06'],
    ["../photos/idaho/Idaho-0621-166.jpg", "../photos/idaho/Idaho-0621-166_large.jpg", "Sawtooth", "Stanley, Idaho | June, 2021", "Featured", '2021-06'],
    ["../photos/idaho/Idaho-0621-167.jpg", "../photos/idaho/Idaho-0621-167_large.jpg", "Sawtooth", "Stanley, Idaho | June, 2021", "Featured", '2021-06'],
    ["../photos/idaho/Idaho-0621-168.jpg", "../photos/idaho/Idaho-0621-168_large.jpg", "Sawtooth", "Stanley, Idaho | June, 2021", "Featured", '2021-06'],
    ["../photos/idaho/Idaho-0621-169.jpg", "../photos/idaho/Idaho-0621-169_large.jpg", "Sawtooth", "Stanley, Idaho | June, 2021", "Featured", '2021-06'],
    ["../photos/idaho/Idaho-0621-169-2.jpg", "../photos/idaho/Idaho-0621-169-2_large.jpg", "Sawtooth", "Stanley, Idaho | June, 2021", "Featured", '2021-06'],
    ["../photos/idaho/Idaho-0621-170.jpg", "../photos/idaho/Idaho-0621-170_large.jpg", "Mountain Woods", "Stanley, Idaho | June, 2021", "Featured", '2021-06'],
    ["../photos/idaho/Idaho-0621-171.jpg", "../photos/idaho/Idaho-0621-171_large.jpg", "Mountain Woods", "Stanley, Idaho | June, 2021", "Featured", '2021-06'],
    ["../photos/idaho/Idaho-0621-172.jpg", "../photos/idaho/Idaho-0621-172_large.jpg", "Mountain Woods", "Stanley, Idaho | June, 2021", "Featured", '2021-06'],

]

// export var fairfieldList = [
//     //["Type [0]" "Thumbnail [1]","Large [2]", "More Info [3]", "Ver/Hor [4]", "Title [5]", "Details [6]", "Keywords [7]",],
    
//     //Fairfield Baptist
//     ["../photos/FairfieldBaptist/FairfieldBaptist-Service-022121-6.jpg", "../photos/FairfieldBaptist/FairfieldBaptist-Service-022121-6.jpg", "February Service", "Fairfield Baptist Church | Cypress, TX | Feburary, 2021", "Fairfield",],
//     ["images/photos/Photo-0071.jpg?v=2", "images/photos/Photo-0071.jpg?v=2", "Worship", "Fairfield Baptist Church | Cypress, TX | Feburary, 2021", "Featured Portrait Black-&-White Fairfield",],
//     ["../photos/FairfieldBaptist/FairfieldBaptist-Service-022121-15.jpg", "../photos/FairfieldBaptist/FairfieldBaptist-Service-022121-15.jpg", "February Service", "Fairfield Baptist Church | Cypress, TX | Feburary, 2021", "Fairfield",],
//     ["images/photos/Photo-0072.jpg?v=2", "images/photos/Photo-0072.jpg?v=2", "Worship", "Fairfield Baptist Church | Cypress, TX | Feburary, 2021", "Black-&-White Fairfield",],
//     ["../photos/FairfieldBaptist/FairfieldBaptist-Service-022121-44.jpg", "../photos/FairfieldBaptist/FairfieldBaptist-Service-022121-44.jpg", "February Service", "Fairfield Baptist Church | Cypress, TX | Feburary, 2021", "Fairfield",],
//     ["../photos/FairfieldBaptist/FairfieldBaptist-Service-022121-52.jpg", "../photos/FairfieldBaptist/FairfieldBaptist-Service-022121-52.jpg", "February Service", "Fairfield Baptist Church | Cypress, TX | Feburary, 2021", "Fairfield",],
//     ["../photos/FairfieldBaptist/FairfieldBaptist-Service-022121-53.jpg", "../photos/FairfieldBaptist/FairfieldBaptist-Service-022121-53.jpg", "February Service", "Fairfield Baptist Church | Cypress, TX | Feburary, 2021", "Fairfield",],
//     ["../photos/FairfieldBaptist/FairfieldBaptist-Baptism-032221-19.jpg", "../photos/FairfieldBaptist/FairfieldBaptist-Baptism-032221-19.jpg", "Baptism", "Fairfield Baptist Church | Cypress, TX | March, 2021", "Fairfield",],
//     ["../photos/FairfieldBaptist/FairfieldBaptist-ChurchCouncil2021-1.jpg", "../photos/FairfieldBaptist/FairfieldBaptist-ChurchCouncil2021-1.jpg", "Church Council 2021", "Fairfield Baptist Church | Cypress, TX | 2021", "Fairfield",],
//     ["../photos/FairfieldBaptist/FairfieldBaptist-ChurchCouncil2021-2.jpg", "../photos/FairfieldBaptist/FairfieldBaptist-ChurchCouncil2021-2.jpg", "Church Council 2021", "Fairfield Baptist Church | Cypress, TX | 2021", "Fairfield",],
//     ["../photos/FairfieldBaptist/FairfieldBaptist-ChurchCouncil2021-3.jpg", "../photos/FairfieldBaptist/FairfieldBaptist-ChurchCouncil2021-3.jpg", "Church Council 2021", "Fairfield Baptist Church | Cypress, TX | 2021", "Fairfield",],
//     ["../photos/FairfieldBaptist/FairfieldBaptist-ChurchCouncil2021-4.jpg", "../photos/FairfieldBaptist/FairfieldBaptist-ChurchCouncil2021-4.jpg", "Church Council 2021", "Fairfield Baptist Church | Cypress, TX | 2021", "Fairfield",],
//     ["../photos/FairfieldBaptist/FairfieldBaptist-ChurchCouncil2021-5.jpg", "../photos/FairfieldBaptist/FairfieldBaptist-ChurchCouncil2021-5.jpg", "Church Council 2021", "Fairfield Baptist Church | Cypress, TX | 2021", "Fairfield",],
//     ["../photos/FairfieldBaptist/FairfieldBaptist-ChurchCouncil2021-6.jpg", "../photos/FairfieldBaptist/FairfieldBaptist-ChurchCouncil2021-6.jpg", "Church Council 2021", "Fairfield Baptist Church | Cypress, TX | 2021", "Fairfield",],
//     ["../photos/FairfieldBaptist/FairfieldBaptist-ChurchCouncil2021-7.jpg", "../photos/FairfieldBaptist/FairfieldBaptist-ChurchCouncil2021-7.jpg", "Church Council 2021", "Fairfield Baptist Church | Cypress, TX | 2021", "Fairfield",],
//     ["../photos/FairfieldBaptist/FairfieldBaptist-ChurchCouncil2021-8.jpg", "../photos/FairfieldBaptist/FairfieldBaptist-ChurchCouncil2021-8.jpg", "Church Council 2021", "Fairfield Baptist Church | Cypress, TX | 2021", "Fairfield",],
//     ["../photos/FairfieldBaptist/FairfieldBaptist-ChurchCouncil2021-9.jpg", "../photos/FairfieldBaptist/FairfieldBaptist-ChurchCouncil2021-9.jpg", "Church Council 2021", "Fairfield Baptist Church | Cypress, TX | 2021", "Fairfield",],
//     ["../photos/FairfieldBaptist/FairfieldBaptist-May23-2021-2.jpg", "../photos/FairfieldBaptist/FairfieldBaptist-May23-2021-2.jpg", "May Service", "Fairfield Baptist Church | Cypress, TX | May, 2021", "Fairfield",],
//     ["../photos/FairfieldBaptist/FairfieldBaptist-May23-2021-3.jpg", "../photos/FairfieldBaptist/FairfieldBaptist-May23-2021-3.jpg", "May Service", "Fairfield Baptist Church | Cypress, TX | May, 2021", "Fairfield",],
//     ["../photos/FairfieldBaptist/FairfieldBaptist-May23-2021-9.jpg", "../photos/FairfieldBaptist/FairfieldBaptist-May23-2021-9.jpg", "May Service", "Fairfield Baptist Church | Cypress, TX | May, 2021", "Fairfield",],
//     ["../photos/FairfieldBaptist/FairfieldBaptist-May23-2021-12.jpg", "../photos/FairfieldBaptist/FairfieldBaptist-May23-2021-12.jpg", "May Service", "Fairfield Baptist Church | Cypress, TX | May, 2021", "Fairfield",],
//     ["../photos/FairfieldBaptist/FairfieldBaptist-May23-2021-15.jpg", "../photos/FairfieldBaptist/FairfieldBaptist-May23-2021-15.jpg", "May Service", "Fairfield Baptist Church | Cypress, TX | May, 2021", "Fairfield",],
//     ["../photos/FairfieldBaptist/FairfieldBaptist-May23-2021-20.jpg", "../photos/FairfieldBaptist/FairfieldBaptist-May23-2021-20.jpg", "May Service", "Fairfield Baptist Church | Cypress, TX | May, 2021", "Fairfield",],
//     ["../photos/FairfieldBaptist/FairfieldBaptist-May23-2021-27.jpg", "../photos/FairfieldBaptist/FairfieldBaptist-May23-2021-27.jpg", "May Service", "Fairfield Baptist Church | Cypress, TX | May, 2021", "Fairfield",],
//     ["../photos/FairfieldBaptist/FairfieldBaptist-May23-2021-35.jpg", "../photos/FairfieldBaptist/FairfieldBaptist-May23-2021-35.jpg", "May Service", "Fairfield Baptist Church | Cypress, TX | May, 2021", "Fairfield",],
//     ["../photos/FairfieldBaptist/FairfieldBaptist-May23-2021-40.jpg", "../photos/FairfieldBaptist/FairfieldBaptist-May23-2021-40.jpg", "May Service", "Fairfield Baptist Church | Cypress, TX | May, 2021", "Fairfield",],
//     ["../photos/FairfieldBaptist/FairfieldBaptist-May23-2021-44.jpg", "../photos/FairfieldBaptist/FairfieldBaptist-May23-2021-44.jpg", "May Service", "Fairfield Baptist Church | Cypress, TX | May, 2021", "Fairfield",],
//     ["../photos/FairfieldBaptist/FairfieldBaptist-May23-2021-41.jpg", "../photos/FairfieldBaptist/FairfieldBaptist-May23-2021-41.jpg", "May Service", "Fairfield Baptist Church | Cypress, TX | May, 2021", "Fairfield",],
//     ["../photos/FairfieldBaptist/FairfieldBaptist-May23-2021-46.jpg", "../photos/FairfieldBaptist/FairfieldBaptist-May23-2021-46.jpg", "May Service", "Fairfield Baptist Church | Cypress, TX | May, 2021", "Fairfield",],
//     ["../photos/FairfieldBaptist/ChildrenMinister_IceCreamSocial-61.jpg", "../photos/FairfieldBaptist/ChildrenMinister_IceCreamSocial-61.jpg", "Ice Cream Social", "Fairfield Baptist Church | Cypress, TX | July, 2021", "Fairfield",],
//     ["../photos/FairfieldBaptist/FairfiieldBaptist-Service-072521-1.jpg", "../photos/FairfieldBaptist/FairfiieldBaptist-Service-072521-1.jpg", "July Service", "Fairfield Baptist Church | Cypress, TX | July, 2021", "Fairfield",],
//     ["../photos/FairfieldBaptist/FairfiieldBaptist-Service-072521-11.jpg", "../photos/FairfieldBaptist/FairfiieldBaptist-Service-072521-11.jpg", "July Service", "Fairfield Baptist Church | Cypress, TX | July, 2021", "Fairfield",],
//     ["../photos/FairfieldBaptist/FairfiieldBaptist-Service-072521-17.jpg", "../photos/FairfieldBaptist/FairfiieldBaptist-Service-072521-17.jpg", "July Service", "Fairfield Baptist Church | Cypress, TX | July, 2021", "Fairfield",],
//     ["../photos/FairfieldBaptist/FairfiieldBaptist-Service-072521-23.jpg", "../photos/FairfieldBaptist/FairfiieldBaptist-Service-072521-23.jpg", "July Service", "Fairfield Baptist Church | Cypress, TX | July, 2021", "Fairfield",],
//     ["../photos/FairfieldBaptist/FairfiieldBaptist-Service-072521-27.jpg", "../photos/FairfieldBaptist/FairfiieldBaptist-Service-072521-27.jpg", "July Service", "Fairfield Baptist Church | Cypress, TX | July, 2021", "Fairfield",],
//     ["../photos/FairfieldBaptist/FairfiieldBaptist-Service-072521-31.jpg", "../photos/FairfieldBaptist/FairfiieldBaptist-Service-072521-31.jpg", "July Service", "Fairfield Baptist Church | Cypress, TX | July, 2021", "Fairfield",],
//     ["../photos/FairfieldBaptist/FairfiieldBaptist-Service-072521-46.jpg", "../photos/FairfieldBaptist/FairfiieldBaptist-Service-072521-46.jpg", "July Service", "Fairfield Baptist Church | Cypress, TX | July, 2021", "Fairfield",],
//     ["../photos/FairfieldBaptist/FairfiieldBaptist-Service-072521-51.jpg", "../photos/FairfieldBaptist/FairfiieldBaptist-Service-072521-51.jpg", "July Service", "Fairfield Baptist Church | Cypress, TX | July, 2021", "Fairfield",],
//     ["../photos/FairfieldBaptist/FairfiieldBaptist-Service-072521-76.jpg", "../photos/FairfieldBaptist/FairfiieldBaptist-Service-072521-76.jpg", "July Service", "Fairfield Baptist Church | Cypress, TX | July, 2021", "Fairfield",],
//     ["../photos/FairfieldBaptist/FairfiieldBaptist-Service-072521-83.jpg", "../photos/FairfieldBaptist/FairfiieldBaptist-Service-072521-83.jpg", "July Service", "Fairfield Baptist Church | Cypress, TX | July, 2021", "Fairfield",],
//     ["../photos/FairfieldBaptist/FairfiieldBaptist-Service-072521-86.jpg", "../photos/FairfieldBaptist/FairfiieldBaptist-Service-072521-86.jpg", "July Service", "Fairfield Baptist Church | Cypress, TX | July, 2021", "Fairfield",],
//     ["../photos/FairfieldBaptist/FairfiieldBaptist-Service-072521-89.jpg", "../photos/FairfieldBaptist/FairfiieldBaptist-Service-072521-89.jpg", "July Service", "Fairfield Baptist Church | Cypress, TX | July, 2021", "Fairfield",],

// ]